import { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { twJoin, twMerge } from 'tailwind-merge';

import type { Model } from 'shared/api/models/types';
import type { ClassName } from 'shared/types';

import { MOCK_NESAS_PICK } from 'pages/GalleryHome/config';
import { getCategoryLabel } from 'pages/GalleryHome/helpers/getCategoryLabel';
import { Tooltip } from 'shared/ui/Tooltip';

import { ContentLoader } from '../ContentLoader';
import { Icon } from '../Icon';
import { StretchedSkeleton } from '../StretchedSkeleton';
import { GalleryCardBottomInfo } from './ui/GalleryCardBottomInfo';

type Props = {
  isDescriptionPreview?: boolean;
  isLoading?: boolean;
  isTrustEnabled?: boolean;
  model?: Model;
  onClick?: () => void;
} & ClassName;

const statuses = [
  { max: 100, min: 75, scoreLabel: 'High', type: 'quality' },
  { max: 74, min: 50, scoreLabel: 'Good', type: 'trust' },
  { max: 24, min: 0, scoreLabel: 'Poor', type: 'flawed' },
  { max: undefined, min: undefined, scoreLabel: 'Not Scored', type: 'unknown' },
] as const;

export const GalleryCard = ({ className, isLoading, isTrustEnabled, model, onClick }: Props) => {
  const [statusImage, setStatusImage] = useState('loading');

  const isNesaPick = model?.name && MOCK_NESAS_PICK.includes(model?.name);
  const stateIndex = isNesaPick ? Math.floor(Math.random() * 2) : 3;
  const { max, min, scoreLabel, type } = statuses[stateIndex];
  const hasScore = !!min && !!max;

  const score = hasScore && Math.round(Math.random() * (max - min) + min);

  return (
    <div
      className={twMerge(
        'group relative rounded-lg border border-transparent bg-white p-3 transition-all duration-300 hover:border-[#ADB0B6] hover:drop-shadow-mdAll',
        className,
      )}
    >
      <AnimatePresence>
        {isTrustEnabled && (
          <>
            <motion.div
              animate={{ opacity: 1 }}
              className={twMerge(
                'absolute inset-x-0 -top-px h-[2px] bg-gradient-to-r from-transparent from-25% via-clay-350 to-transparent to-75%',
                type === 'flawed' && 'via-red-800',
                type === 'trust' && 'via-green-600',
                type === 'quality' && 'via-[#173BDA]',
              )}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
            />
            <div className="absolute inset-x-0 -top-px h-14 overflow-hidden opacity-0 transition-opacity group-hover:opacity-100">
              <div
                className={twMerge(
                  'inset-x-0 -top-px h-full -translate-y-1/2 bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-clay-350/20 from-0% to-50%',
                  type === 'flawed' && 'from-red-800/20',
                  type === 'trust' && 'from-green-600/20',
                  type === 'quality' && 'from-[#173BDA]/20',
                )}
              />
            </div>
          </>
        )}
      </AnimatePresence>
      <div className={twJoin('flex flex-col', !!onClick && 'cursor-pointer')} onClick={onClick}>
        <div className="relative flex items-start justify-between">
          <div className="size-11 overflow-hidden rounded-lg xs:size-10 xs:min-w-10 lg:size-11 lg:min-w-11">
            {!isLoading && (
              <img
                className="size-full overflow-hidden object-cover object-center"
                onError={() => setStatusImage('error')}
                onLoad={() => setStatusImage('success')}
                src={model?.tinyImage || model?.image?.replace('storage.googleapis.com/', '')}
              />
            )}
            {statusImage === 'loading' && (
              <ContentLoader className="absolute left-0 top-0" height="100%" width="100%">
                <rect height="100%" rx="5" ry="5" width="2.75rem" x="0" y="0" />
              </ContentLoader>
            )}
          </div>
          <div className="-mt-1">
            <div className="relative flex h-6 min-w-16 max-w-fit items-center gap-1 !pl-1.5 text-sm font-semibold leading-6 text-black">
              <StretchedSkeleton enable={isLoading} rx={12} ry={12} />

              {!isLoading && (
                <>
                  <AnimatePresence>
                    {isTrustEnabled && (
                      <motion.div
                        animate={{ opacity: 1 }}
                        className="border-r border-clay-20 pr-2"
                        exit={{ opacity: 0 }}
                        initial={{ opacity: 0 }}
                      >
                        <Tooltip
                          content={
                            <div className="flex flex-col items-center justify-center gap-1 p-2 text-sm/none font-light">
                              <div className="leading-none">Nesa Trust score:</div>
                              <div className="font-semibold leading-none">
                                {scoreLabel} {hasScore ? `(${score})` : ''}
                              </div>
                            </div>
                          }
                          delayDuration={200}
                          side="top"
                        >
                          <Icon
                            className={twMerge(
                              'size-4 text-clay-350',
                              type === 'flawed' && 'text-red-800',
                              type === 'trust' && 'text-green-600',
                              type === 'quality' && 'text-[#173BDA]',
                            )}
                            name="shield"
                          />
                        </Tooltip>
                      </motion.div>
                    )}
                  </AnimatePresence>

                  <Tooltip
                    className="px-3 py-2 text-xs"
                    content={
                      model?.pricing ? (
                        <div className="flex flex-col gap-1 text-xs">
                          <div className="flex items-center justify-between gap-3 font-light">
                            <div className="text-clay-100">
                              <b>${model.pricing.input_price.toFixed(3)}</b> / 1M tokens
                            </div>
                            <div className="text-[#61728E]">input</div>
                          </div>
                          <div className="flex items-center justify-between gap-3 font-light">
                            <div className="text-clay-100">
                              <b>${model.pricing.output_price.toFixed(3)}</b> / 1M tokens
                            </div>
                            <div className="text-[#61728E]">output</div>
                          </div>
                        </div>
                      ) : (
                        'per token'
                      )
                    }
                    side="bottom"
                  >
                    <span className="ml-auto mt-px font-semibold text-clay-900">
                      ${model?.pricing?.output_price?.toFixed(3) || model?.price?.toFixed(3) || 0.001}
                    </span>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="relative mt-3 flex h-6 min-w-fit items-center gap-1">
          {!isLoading ? (
            <>
              {model?.type && (
                <div className="rounded border border-blue-50 p-1.5 text-xs/none font-semibold uppercase text-clay-300">
                  {getCategoryLabel(model.type)}
                </div>
              )}
              {model?.tags?.includes('nesas-pick') && (
                <div className="min-w-fit rounded border border-primary-150 bg-primary-30 p-1.5 text-xs/none font-semibold uppercase text-primary-800">
                  Nesa&apos;s Pick
                </div>
              )}

              {/* {model?.tags?.includes('featured') && (
                <div className="rounded border border-tusk-200 bg-green-100/40 p-1.5 text-xs/none font-semibold uppercase text-green-500">
                  Featured
                </div>
              )} */}
            </>
          ) : (
            <StretchedSkeleton enable={isLoading} />
          )}
        </div>

        <div className="mt-1.5 flex flex-1 flex-col justify-between">
          <div className="flex">
            <div className="relative flex-1 grow">
              <div className="mb-1 line-clamp-1 h-6 text-ellipsis break-all text-lg/6 font-semibold capitalize">
                <StretchedSkeleton enable={isLoading} />

                {!isLoading && model?.modelName}
              </div>

              <div
                className={twMerge(
                  'line-clamp-2 h-10 text-ellipsis whitespace-pre-line text-sm font-light text-clay-380',
                )}
              >
                <StretchedSkeleton enable={isLoading} />

                {!isLoading && model?.description}
              </div>
            </div>
          </div>

          <div className="mt-3 h-px w-full bg-blue-50" />
        </div>
      </div>
      <GalleryCardBottomInfo
        className="mt-3 xs:@md:hidden"
        isLoading={isLoading}
        latency={model?.latency}
        reviewAvg={model?.avgScore}
        reviewCount={model?.reviewsCount}
      />
    </div>
  );
};
