import type { Id } from 'react-toastify';

import { type FormEvent, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, Navigate, useNavigate } from 'react-router-dom';

import { AxiosError } from 'axios';
import isEmail from 'validator/es/lib/isEmail';

import { useUser } from 'app/stores/user';
import { useLoginMutation } from 'shared/api/user/useLoginMutation';
import { Button } from 'shared/ui/Button';
import { Checkbox } from 'shared/ui/Checkbox';
import { Input } from 'shared/ui/Input';
import { toaster } from 'shared/ui/Toast';

import { AppleButton } from '../ui/AppleButton';
import { GoogleButton } from '../ui/GoogleButton';
import { Header } from '../ui/Header';
import { Or } from '../ui/Or';

interface FormValues {
  email: string;
  password: string;
}

export const SignIn = () => {
  const { updateUser, user } = useUser();

  const navigate = useNavigate();

  const toastId = useRef<Id | undefined>();

  const [isRemember, setIsRemember] = useState(false);

  const { isPending: isPendingLogin, mutateAsync: login } = useLoginMutation();

  const {
    formState: { errors, isValid },
    getValues,
    register,
    reset,
  } = useForm<FormValues>({
    defaultValues: { email: '' },
    mode: 'onChange',
  });

  const handleLogin = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!isValid) {
      toastId.current = toaster.error(
        'Please fill in the required fields',
        {},
        { toastId: toastId.current || undefined, updateId: toastId.current || undefined },
      );
      return;
    }

    try {
      const values = getValues();

      const { user, whitelist } = await login({ ...values, remember: isRemember });

      if (whitelist) {
        navigate('/whitelist');
        return;
      }

      await updateUser(user);
      reset();
      navigate('/');
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  if (user) {
    return <Navigate replace to="/" />;
  }

  return (
    <>
      <Header title="Sign In" />
      <div className="flex flex-col gap-3">
        <GoogleButton />
        <AppleButton />
      </div>
      <Or />
      <form className="flex flex-col" onSubmit={handleLogin}>
        <div className="flex flex-col gap-1">
          <Input
            errorSpacing
            {...register('email', { validate: (val) => (!isEmail(val) ? 'Invalid email' : true) })}
            disabled={isPendingLogin}
            error={errors.email?.message}
            label={
              <span>
                Email <span className="text-red-900">*</span>
              </span>
            }
            placeholder="Email Address"
          />
          <Input
            errorSpacing
            {...register('password')}
            disabled={isPendingLogin}
            error={errors.password?.message}
            label={
              <span>
                Password <span className="text-red-900">*</span>
              </span>
            }
            placeholder="Password"
            type="password"
          />
        </div>
        <div className="mb-4 flex items-center justify-between">
          <Checkbox.CheckboxWrapper>
            <Checkbox.Control
              checked={isRemember}
              onChange={(e) => setIsRemember(e.target.checked)}
              value="remember"
            />
            <Checkbox.CheckboxLabel>Remember me</Checkbox.CheckboxLabel>
          </Checkbox.CheckboxWrapper>
          <Link
            className="cursor-pointer text-sm text-clay-300 duration-200 hover:text-primary-800"
            to="/auth/recovery-password"
          >
            Forgot Password?
          </Link>
        </div>

        <Button disabled={isPendingLogin} isLoading={isPendingLogin} type="submit">
          Login
        </Button>
      </form>
      <div className="mt-5 text-center text-sm font-semibold text-clay-900">
        {`Don't have an account?`}{' '}
        <Link
          className="cursor-pointer text-primary-800 duration-200 hover:text-primary-900"
          to="/auth/signup"
        >
          Register
        </Link>
      </div>
    </>
  );
};
