import type { IconName } from 'shared/ui/Icon';

const CATEGORY_LABEL_MAP = {
  'automatic-speech-recognition': {
    description: 'Transcribing spoken language into text.',
    title: 'Speech\nRecognition\nASR',
  },
  'depth-estimation': {
    description: 'Predicting depth to build 3D scenes from images.',
    title: 'Depth\nEstimation',
  },
  'feature-extractor': {
    description: 'Extracting high-level features from diverse data types.',
    title: 'Feature\nExtraction',
  },
  'image-classification': {
    description: 'Categorizing and identifying objects or scenes in images.',
    title: 'Image\nClassification',
  },
  'image-generation': {
    description: 'Generating images from textual descriptions to visualize concepts and ideas.',
    title: 'Image\nGeneration',
  },
  'image-segmentation': {
    description: 'Segmenting images into meaningful components.',
    title: 'Image\nSegmentation',
  },
  'instance-segmentation': { description: '', title: 'Instance\nSegmentation' },
  'named-entity-recognition': {
    description: '',
    title: 'NER Recognition',
  },
  'object-detection': {
    description: 'Detecting and localizing objects in images.',
    title: 'Object\nDetection',
  },
  ocr: {
    description: 'Extracting text from images and documents.',
    title: 'OCR Recognition',
  },
  'panoptic-segmentation': { description: '', title: 'Panoptic\nSegmentation' },
  'question-answering': {
    description: 'Answering queries with contextual understanding.',
    title: 'Question\nAnswering',
  },

  summarization: {
    description: 'Creating concise summaries of longer texts.',
    title: 'Content\nSummarization',
  },
  'text-classification': {
    description: 'Categorizing text for tasks like sentiment or topic classification.',
    title: 'Text\nClassification',
  },
  'text-generation': {
    description: 'Generating coherent and relevant text across domains.',
    title: 'Text\nGeneration',
  },
  'token-classification': {
    description: 'Labeling words and tokens for tasks like named entity recognition.',
    title: 'Token\nClassification',
  },
  translation: {
    description: 'Translating text across languages while maintaining meaning.',
    title: 'Language\nTranslation',
  },
  'unconditional-image-gen': {
    description: '',
    title: 'Unconditional Gen',
  },
};

type FilterItem = {
  icon: IconName;
  value: string;
};

export const filterItems: FilterItem[] = [
  { icon: 'box', value: 'all' },
  { icon: 'textGeneration', value: 'text-generation' },
  { icon: 'imageGeneration', value: 'image-generation' },
  { icon: 'videoGeneration', value: 'video-generation' },
  { icon: 'text2Speech', value: 'text-to-speech' },
  { icon: 'qa', value: 'question-answering' },
  { icon: 'language', value: 'translation' },
  { icon: 'imageSegmentation', value: 'image-segmentation' },
  { icon: 'autoSpeech', value: 'automatic-speech-recognition' },
  { icon: 'textClassification', value: 'text-classification' },
  { icon: 'speech2Text', value: 'speech-to-text' },
  { icon: 'audioClassification', value: 'audio-classification' },
  { icon: 'sentenceSimilarity', value: 'sentence-similarity' },
  { icon: 'contentSummarization', value: 'summarization' },
  { icon: 'namedEntRec', value: 'named-entity-recognition' },
  { icon: 'objDetect', value: 'object-detection' },
  { icon: 'ocr', value: 'ocr' },
  { icon: 'imageClassification', value: 'image-classification' },
  { icon: 'metMod', value: 'metabolic-modeling' },
  { icon: 'systemsBio', value: 'system-biology' },
  { icon: 'spatialTrans', value: 'spatial-transcriptomics' },
  { icon: 'structBio', value: 'structual-bioinformatics' },
  { icon: 'unconImgGen', value: 'unconditional-image-gen' },
  { icon: 'depthEst', value: 'depth-estimation' },
  { icon: 'featureExtr', value: 'feature-extractor' },
  { icon: 'timeSeries', value: 'time-series-forecasting' },
  { icon: 'superRes', value: 'super-resolution' },
  { icon: 'centralized', value: 'centralized-models' },
];

export const getCategoryIcon = (category: string) => {
  return filterItems.find((el) => el.value === category)?.icon || 'box';
};

export const getCategoryLabel = (category: string) => {
  if (!category) {
    return '';
  }

  return (
    CATEGORY_LABEL_MAP[category as keyof typeof CATEGORY_LABEL_MAP]?.title ||
    category
      .split('-')
      .reduce((acc, str, i) => `${acc}${i > 0 ? ' ' : ''}${str.charAt(0).toUpperCase()}${str.slice(1)}\n`, '')
  );
};

export const getCategoryDescription = (category: string) => {
  return CATEGORY_LABEL_MAP[category as keyof typeof CATEGORY_LABEL_MAP]?.description || '';
};
