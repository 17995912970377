import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const FaucetPageLazy = lazy(() => import('./Faucet').then((module) => ({ default: module.Faucet })));

export const Faucet = () => (
  <Suspense fallback={<PageLoader />}>
    <FaucetPageLazy />
  </Suspense>
);
