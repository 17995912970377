import { useFormContext } from 'react-hook-form';

import { AnimatePresence, motion } from 'framer-motion';

import { useUploadModelMutation } from 'shared/api/models/useUploadModelMutation';
import { Button } from 'shared/ui/Button';
import { TextArea } from 'shared/ui/TextArea';

import { StepHeader } from './StepHeader';

type Props = {
  isExpanded: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
  onTitleClick: () => void;
};

export const Description = ({ isExpanded, onNextClick, onPrevClick, onTitleClick }: Props) => {
  const { isPending: isUploading } = useUploadModelMutation();

  const { formState, register, watch } = useFormContext();

  const description = watch('description');

  return (
    <>
      <StepHeader
        icon="text"
        isActive={isExpanded}
        isCompleted={!!description}
        onClick={onTitleClick}
        title="Description"
      />

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            animate={{ height: 'auto' }}
            className="flex shrink-0 flex-col gap-3 overflow-hidden"
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: 0 }}
            layout
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            <TextArea
              {...register('description', { required: 'Model description is required' })}
              className="mx-4 mt-4 h-full px-0"
              disabled={isUploading}
              error={formState.errors?.['description']?.message?.toString() || undefined}
              innerClassName="mt-1 grow-0 text-sm"
              label={
                <>
                  {`Your model's description`}
                  <span className="ml-1 text-red-900">*</span>
                </>
              }
              labelClassName="text-xs font-light text-clay-500"
              placeholder="Ex. This model generate images..."
              rows={8}
            />

            <div className="mx-4 flex justify-between">
              <Button
                className="rounded-lg bg-clay-20 px-3 text-clay-900"
                color="secondary"
                onClick={onPrevClick}
                variant="filled-light"
              >
                Back
              </Button>

              <Button className="rounded-lg px-3" isLoading={isUploading} onClick={onNextClick}>
                Next
              </Button>
            </div>

            <div className="mt-1 border-b border-clay-20"></div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
