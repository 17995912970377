import type { HTMLMotionProps } from 'framer-motion';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useIntersectionObserver } from '@uidotdev/usehooks';
import { AnimatePresence, motion } from 'framer-motion';

import type { Model } from 'shared/api/models/types';
import type { ClassName } from 'shared/types';

import { filterItems, getCategoryLabel } from 'pages/GalleryHome/helpers/getCategoryLabel';
import { useEvent } from 'shared/hooks/useEvent';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { GalleryCard } from 'shared/ui/GalleryCard';
import { Icon, type IconName } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';
import { Spinner } from 'shared/ui/Spinner';

import type { FilterState, SortFilter } from '../GalleryFilters/GalleryFilters';

type Props = {
  fetchNextPage: () => void;
  filters: FilterState;
  hasNextPage: boolean;
  isFetchingNextPage: boolean;
  isPending?: boolean;
  modelList: Model[] | undefined;
  onFilterChange: (filters: FilterState) => void;
} & ClassName &
  HTMLMotionProps<'div'>;

const getIconByValue = (value: string): IconName => {
  const item = filterItems.find((item) => item.value === value);
  return item?.icon ?? 'box';
};

export const Gallery = ({
  fetchNextPage,
  filters,
  hasNextPage,
  isFetchingNextPage,
  isPending,
  modelList,
  onFilterChange,
}: Props) => {
  const [searchValue, setSearchValue] = useState('');
  const [ref, intersection] = useIntersectionObserver<HTMLDivElement>();

  const navigate = useNavigate();
  const itemsCount = modelList?.length ?? 0;
  const sm = useMinWidthMediaQuery('sm');
  const lg = useMinWidthMediaQuery('lg');
  const xl = useMinWidthMediaQuery('xl');

  const onSortChange = (value: SortFilter) => {
    onFilterChange({ sort: value });
  };

  useEffect(() => {
    if (intersection?.isIntersecting && hasNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, intersection?.isIntersecting]);

  const handleReset = () => {
    setSearchValue('');
    onFilterChange({ globalSearch: '' });
  };

  const selectedTypes = filters.type || [];

  const getYPosition = useEvent((index: number) => {
    const baseY = 50;

    if (xl) {
      return ((index % 4) + 1) * baseY;
    } else if (lg) {
      return ((index % 3) + 1) * baseY;
    } else if (sm) {
      return ((index % 2) + 1) * baseY;
    } else {
      return ((index % 1) + 1) * baseY;
    }
  });

  return (
    <div className="mt-3 flex size-full flex-col overflow-hidden rounded-2xl bg-blue-50 p-2">
      {!sm && (
        <div className="mb-2 flex items-center justify-between">
          <span className="ml-2 text-base font-bold text-blue-900">Filter</span>
          <div className="right-4 box-border flex h-11 items-center gap-1 rounded-xl bg-blue-50 p-1">
            <Select
              className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
              iconClassName="mr-1"
              iconName="arrowDownUp"
              onValueChange={onSortChange}
              value={filters.sort ? filters.sort : undefined}
            >
              <Select.Content className="z-10 p-1">
                <div className="mb-2 w-48 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                  Sort by
                </div>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="newest"
                >
                  Newest
                </Select.Item>
                <Select.Item
                  className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="oldest"
                >
                  Oldest
                </Select.Item>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="name-lowest"
                >
                  Name Ascending
                </Select.Item>
                <Select.Item
                  className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="name-highest"
                >
                  Name Decending
                </Select.Item>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="ranking-highest"
                >
                  Most Rated
                </Select.Item>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="likes-highest"
                >
                  Most Likes
                </Select.Item>
                <Select.Item
                  className="border-b border-blue-50 text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="downloads-highest"
                >
                  Most Downloaded
                </Select.Item>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="price-highest"
                >
                  Price Highest
                </Select.Item>
                <Select.Item
                  className="text-sm text-clay-700 hover:bg-blue-50"
                  indicatorClassName="size-3 text-clay-700"
                  value="price-lowest"
                >
                  Price Lowest
                </Select.Item>
              </Select.Content>
            </Select>

            <Select
              className="h-9 w-fit rounded-lg bg-gray-100 p-3 text-sm inner-border-0"
              disabled
              iconClassName="mr-1"
              iconName="filter"
            >
              <Select.Content className="z-10 px-2 pb-2 pt-1">
                <div className="mb-2 border-b border-blue-50 px-2 pb-2.5 pt-2 text-sm font-medium text-blue-800">
                  Filter
                </div>

                <div className="flex w-full flex-col">
                  <div className="flex justify-between pb-1.5">
                    <div className="text-xs font-medium text-clay-500">Data Range</div>
                    <div className="text-xs font-medium text-primary-800">Reset</div>
                  </div>

                  <div className="flex flex-col gap-1">
                    <div className="h-3.5 text-[0.6875rem] font-medium text-clay-300">From</div>
                    <div className="h-9 rounded-lg border border-corduroy-150 px-2 py-2.5">hi</div>
                  </div>
                </div>

                <div className="mt-2 flex h-8 w-full justify-between">
                  <div className="flex items-center gap-2 rounded-lg bg-clay-10 pl-2.5 pr-3">
                    <Icon className="size-3 text-blue-800" name="refresh" />
                    <div className="mt-px text-[0.8125rem] font-medium text-blue-800">Reset all</div>
                  </div>

                  <div className="flex items-center gap-2 rounded-lg bg-primary-800 pl-2.5 pr-3">
                    <Icon className="size-3 text-tusk-100" name="check" />
                    <div className="mt-px text-[0.8125rem] font-semibold text-white">Apply</div>
                  </div>
                </div>
              </Select.Content>
            </Select>
          </div>
        </div>
      )}

      {sm && (selectedTypes.length > 0 || searchValue.length > 0) && (
        <div className="mb-2 flex shrink-0 pl-2">
          <span className="mr-4 text-sm/9 font-semibold text-clay-900">Results</span>
          <div className="flex w-full flex-wrap items-center gap-1">
            {selectedTypes.map((item) => (
              <div
                className="flex h-9 shrink-0 items-center justify-between rounded-lg bg-white p-3 text-clay-300"
                key={item}
              >
                <div className="flex gap-2">
                  <Icon className="size-3 text-blue-800" name={getIconByValue(item)} />
                  <span className="text-xs">{getCategoryLabel(item)}</span>
                </div>

                <Icon
                  className="ml-3 size-2 cursor-pointer rounded p-1 text-blue-800 hover:bg-clay-20"
                  name="x"
                  onClick={() => {
                    if (!filters.type) return;

                    const typesToSet = filters.type.filter((typeItem) => typeItem !== item);
                    onFilterChange({
                      ...filters,
                      globalSearch: typesToSet.length === 0 ? undefined : filters.globalSearch,
                      type: typesToSet,
                    });

                    if (typesToSet.length === 0) {
                      setSearchValue('');
                    }
                  }}
                />
              </div>
            ))}
          </div>

          <Input
            className="group h-9 max-w-80 justify-items-center"
            classNameInputWrapper="rounded-xl px-2 shadow-sm"
            endSlot={
              searchValue && (
                <Icon
                  className="cursor-pointer text-corduroy-500 transition-colors hover:text-primary-900"
                  name="close"
                  onClick={handleReset}
                />
              )
            }
            onChange={(e) => {
              const value = e.target.value;

              if (!value) {
                handleReset();
                return;
              }

              setSearchValue(value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                onFilterChange({ globalSearch: searchValue });
              }
            }}
            placeholder="Filtered search"
            startSlot={
              <Icon
                className="size-3.5 text-clay-900 transition-colors group-focus-within:text-clay-600 sm:size-4 sm:text-clay-300"
                name="search"
              />
            }
            value={searchValue}
          />
        </div>
      )}

      <div className="grow overflow-y-auto scrollbar-none">
        {isPending ? (
          <div className="flex w-full flex-col items-center justify-between py-14">
            <Spinner className="size-8" />
          </div>
        ) : (
          <div className="grid w-full grid-flow-dense grid-cols-1 justify-between gap-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {itemsCount > 0 ? (
              modelList?.map((model, i) => {
                return (
                  <AnimatePresence key={model._id}>
                    <motion.div
                      animate={{ opacity: 1, y: 0 }}
                      className="group/card cursor-pointer"
                      initial={{ opacity: 0, y: getYPosition(i) }}
                      key={model._id}
                      onClick={() => {
                        navigate(`/models/${model._id}`);
                      }}
                      ref={i === modelList.length - 2 ? ref : undefined}
                      transition={{ duration: 0.8 }}
                    >
                      <GalleryCard
                        isDescriptionPreview
                        isTrustEnabled={filters.trustEnabled}
                        model={model}
                        // tags={MOCK_NESAS_PICK.includes(model.name) ? ['nesas-pick'] : []}
                      />
                    </motion.div>
                  </AnimatePresence>
                );
              })
            ) : (
              <div className="py-5 text-center text-clay-380 sm:col-span-4">No items found</div>
            )}
            {isFetchingNextPage && (
              <>
                <GalleryCard isLoading />
                <GalleryCard isLoading />
                <GalleryCard isLoading />
                <GalleryCard isLoading />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
