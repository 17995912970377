import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance, setupInterceptors } from 'shared/config/axiosInstance';

import type { AuthResponse, GoogleCallbackParams } from './types';

export const useGoogleCallbackMutation = (
  options: UseMutationOptions<AuthResponse, Error, GoogleCallbackParams> = {},
) => {
  return useMutation({
    mutationFn: async (params: GoogleCallbackParams) => {
      const { data } = await axiosInstance.get<AuthResponse>('/auth/google/callback', { params });

      setupInterceptors(data?.access_token);

      return data;
    },
    ...options,
  });
};
