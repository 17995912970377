import type { ReactNode } from 'react';
import { useState } from 'react';

import { FilterProvider } from '../../../features/FiltersProvider';
import { Footer } from '../Footer';
import { GalleryHeader } from '../GalleryHeader';
import { GalleryLayoutWrapper } from '../GalleryLayoutWrapper';
import { GallerySidebar } from '../GallerySidebar';

type Props = {
  children: ReactNode;
};

export const GalleryLayout = ({ children }: Props) => {
  const [isMobileSidebarOpen, setIsMobileSidebarOpen] = useState(false);

  return (
    <FilterProvider>
      <GalleryLayoutWrapper
        footer={<Footer />}
        header={<GalleryHeader onOpenSidebar={() => setIsMobileSidebarOpen(true)} />}
        sidebar={
          <GallerySidebar isMobileSidebarOpen={isMobileSidebarOpen} onOpenChange={setIsMobileSidebarOpen} />
        }
      >
        {children}
      </GalleryLayoutWrapper>
    </FilterProvider>
  );
};
