import { useController, useFormContext } from 'react-hook-form';

import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import { useGetModelTypesQuery } from 'shared/api/models/useGetModelTypesQuery';
import { useUploadModelMutation } from 'shared/api/models/useUploadModelMutation';
import { Button } from 'shared/ui/Button';
import { Label } from 'shared/ui/Label';
import { Select } from 'shared/ui/Select';
import { TextArea } from 'shared/ui/TextArea';

import { StepHeader } from './StepHeader';

type Props = {
  isExpanded: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
  onTitleClick: () => void;
};

const deviceTypes = [
  { label: 'GPU Worker', value: 'gpu-worker' },
  { label: 'CPU Worker', value: 'cpu-worker' },
];

export const HardwareSpecifications = ({ isExpanded, onNextClick, onPrevClick, onTitleClick }: Props) => {
  const { isPending: isUploading } = useUploadModelMutation();
  const { isLoading: isLoadingModelTypes } = useGetModelTypesQuery({
    allowed_custom: true,
  });

  const { control, register } = useFormContext();

  const { field: deviceTypeField } = useController({
    control,
    name: 'deviceType',
    rules: { required: true },
  });

  const { field: brandField } = useController({
    control,
    name: 'brand',
    rules: { required: true },
  });

  const { field: hardwareField } = useController({
    control,
    name: 'hardware',
    rules: { required: true },
  });

  return (
    <>
      <StepHeader
        icon="cpu"
        isActive={isExpanded}
        isCompleted={!!brandField.value && !!hardwareField.value}
        onClick={onTitleClick}
        title="Hardware Specifications"
      />

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            animate={{ height: 'auto' }}
            className="flex shrink-0 flex-col gap-3 overflow-hidden"
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: 0 }}
            layout
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            <div className="mt-4 px-4">
              <Label className="mb-2 text-xs font-light text-clay-500">
                Device Type <span className="ml-1 text-red-900">*</span>
              </Label>

              <div className="flex w-fit rounded-md bg-clay-20 p-1">
                {deviceTypes.map(({ label, value }) => {
                  return (
                    <div
                      className={twMerge(
                        'cursor-pointer rounded bg-transparent p-2 text-sm font-semibold text-clay-900 transition-colors hover:text-primary-800',
                        deviceTypeField.value === value && 'bg-white',
                      )}
                      key={value}
                      onClick={() => deviceTypeField.onChange(value)}
                    >
                      {label}
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-3 px-4">
              <Select
                className="relative border border-clay-20 bg-white px-3 shadow-sm "
                classNameLabel="mb-2 text-xs font-light text-clay-500"
                disabled={isLoadingModelTypes || isUploading}
                label={
                  <>
                    Brand <span className="ml-1 text-red-900">*</span>
                  </>
                }
                onValueChange={(val) => {
                  brandField.onChange(val);
                }}
                placeholder="Select Option"
                value={brandField.value}
              >
                <Select.Content className="z-50">
                  <Select.Item key="Nvidia" value="Nvidia">
                    Nvidia
                  </Select.Item>
                  <Select.Item key="AMD" value="AMD">
                    AMD
                  </Select.Item>
                </Select.Content>
              </Select>

              <Select
                className="border border-clay-20 bg-white px-3 shadow-sm"
                classNameLabel="mb-2 text-xs font-light text-clay-500"
                disabled={isLoadingModelTypes || isUploading}
                label={
                  <>
                    Hardware <span className="ml-1 text-red-900">*</span>
                  </>
                }
                onValueChange={(val) => {
                  hardwareField.onChange(val);
                }}
                placeholder="Select Option"
                value={hardwareField.value}
              >
                <Select.Content className="z-50">
                  <Select.Item key="3090" value="3090">
                    RTX 3090
                  </Select.Item>
                  <Select.Item key="3080" value="3080">
                    RTX 3080
                  </Select.Item>
                </Select.Content>
              </Select>
            </div>

            <TextArea
              {...register('hardwareDescription')}
              className="mx-4 p-0"
              disabled={isUploading}
              innerClassName="mt-1 grow-0 text-sm"
              label="Other Description"
              labelClassName="text-xs font-light text-clay-500"
              placeholder="If needed"
            />

            <div className="mx-4 flex justify-between">
              <Button
                className="rounded-lg bg-clay-20 px-3 text-clay-900"
                color="secondary"
                onClick={onPrevClick}
                variant="filled-light"
              >
                Back
              </Button>

              <Button className="rounded-lg px-3" isLoading={isUploading} onClick={onNextClick}>
                Next
              </Button>
            </div>

            <div className="mt-1 border-b border-clay-20"></div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
