import { type FormEventHandler, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { AxiosError } from 'axios';

import { useResetPasswordMutation } from 'shared/api/user/useResetPasswordMutation';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { toaster } from 'shared/ui/Toast';

import { Header } from '../ui/Header';
import CheckIcon from './assets/check.svg?react';

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { token, userId } = useParams<{ token: string; userId: string }>();
  const { isPending, mutateAsync } = useResetPasswordMutation();

  const [isPasswordReset, setIsPasswordReset] = useState(false);

  const {
    formState: { errors, isValid },
    getValues,
    register,
  } = useForm<FormValues>({
    defaultValues: { password: '', passwordConfirmation: '' },
    mode: 'onChange',
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      e.preventDefault();

      if (!token || !userId) {
        toaster.error('Invalid data. Please try again.');
        return;
      }

      const { password } = getValues();

      await mutateAsync({ password, token, userId });

      setIsPasswordReset(true);
      // toaster.success('Password reset was successful');

      // navigate('/');
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  if (isPasswordReset) {
    return (
      <div className="text-center">
        <div className="mb-4 flex justify-center">
          <CheckIcon className="size-14" />
        </div>
        <div className="text-2xl/none font-semibold text-clay-900 lg:text-3xl/none 2xl:text-4xl/none">
          Password changed
        </div>
        <div className="mb-6 mt-3 text-sm/4 text-clay-500 2xl:text-base/5">
          Activate 2FA for easier login process.
        </div>
        <Button className="mx-auto flex w-full max-w-sm" onClick={() => navigate('/')} size="medium">
          Sign In
        </Button>
      </div>
    );
  }

  return (
    <>
      <Header
        classNameDescription="whitespace-pre-line"
        description={`Strong password include numbers, letters, and\npunctuation marks.`}
        title="Reset your password"
      />
      <form className="flex flex-col gap-1" onSubmit={handleSubmit}>
        <Input
          disabled={isPending}
          errorSpacing
          placeholder="New Password"
          type="password"
          {...register('password', {
            validate: (val) => {
              return val.length < 6 ? 'Password should have at least 6 characters' : true;
            },
          })}
          error={errors.password?.message}
        />
        <Input
          disabled={isPending}
          errorSpacing
          placeholder="New Password Confirmation"
          type="password"
          {...register('passwordConfirmation', {
            validate: (value, formValues) =>
              formValues.password !== value ? 'Passwords do not match' : value.length >= 6,
          })}
          error={errors.passwordConfirmation?.message}
        />
        <Button disabled={!isValid} isLoading={isPending} type="submit">
          Reset password
        </Button>
      </form>
    </>
  );
};
