import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { StripePayment } from './types';

export const useGetPaymentBySessionIdMutation = (
  options: UseMutationOptions<StripePayment, unknown, string> = {},
) => {
  return useMutation({
    mutationFn: async (sessionId: string) => {
      const { data } = await axiosInstance.post<StripePayment>('/stripe/fulfill-checkout', { sessionId });

      return data;
    },
    ...options,
  });
};
