import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { AxiosError } from 'axios';

import { useUser } from 'app/stores/user';
import { useGoogleCallbackMutation } from 'shared/api/user/useGoogleCallbackMutation';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';

import { Header } from '../ui/Header';

export const GoogleCallback = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { updateUser } = useUser();

  const { mutateAsync: googleCallback } = useGoogleCallbackMutation();

  useEffect(() => {
    (async () => {
      try {
        const code = searchParams.get('code');
        const scope = searchParams.get('scope');
        const authuser = searchParams.get('authuser');
        const prompt = searchParams.get('prompt');

        if (!code || !scope || !authuser || !prompt) {
          toaster.error('Error Google params');
          return navigate('/auth');
        }

        const { user, whitelist } = await googleCallback({ authuser, code, prompt, scope });

        if (whitelist) {
          navigate('/whitelist');
          return;
        }

        await updateUser(user);
        navigate('/');
      } catch (error) {
        if (error instanceof AxiosError) {
          const axiosError = error.response?.data?.message;
          toaster.error(axiosError || 'Something went wrong');
        } else {
          toaster.error('Something went wrong');
        }
        navigate('/');
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Header title="Sign In" />
      <div className=" flex h-40 items-center justify-center">
        <Spinner className="size-8" />
      </div>
    </>
  );
};
// code=4%2F0AVG7fiRih3cnGjcEGipUi6v8jftklAfNh7m0Z1wrvn0J0xPsPXuiwo_K_C7o3Y2DbnzqXA&scope=email+profile+openid+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile+https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email&authuser=0&prompt=consent
