import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const ModelsPageLazy = lazy(() =>
  import('./ModelCollections').then((module) => ({ default: module.ModelCollections })),
);

export const ModelCollections = () => (
  <Suspense fallback={<PageLoader />}>
    <ModelsPageLazy />
  </Suspense>
);
