import type { ReactNode } from 'react';

import { twJoin, twMerge } from 'tailwind-merge';

type Props = {
  children?: ReactNode;
  className?: string;
  hidden?: boolean;
  reverse?: boolean;
  title?: string;
  value?: ReactNode;
  width: number;
};

export const AuthCard = ({ children, className, hidden, reverse, title, value, width }: Props) => {
  return (
    <div
      className={twMerge(
        'relative max-h-full overflow-hidden rounded-[1.25rem] p-6',
        hidden ? 'bg-transparent' : 'bg-[#11162899] backdrop-blur-[36px]',
        className,
      )}
      style={{ height: `${width}px`, width: `${width}px` }}
    >
      {children}
      {title && (
        <div
          className={twJoin(
            'absolute text-lg/5 font-semibold text-clay-300',
            reverse ? 'bottom-6 left-6' : 'right-6 top-6',
          )}
        >
          {title}
        </div>
      )}
      {value && (
        <div
          className={twJoin(
            'absolute text-[4vw] font-semibold leading-none text-white',
            reverse ? 'right-6 top-6' : 'bottom-6 left-6',
          )}
        >
          {value}
        </div>
      )}
    </div>
  );
};
