import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const ModelsPageLazy = lazy(() =>
  import('./ModelCollectionId').then((module) => ({ default: module.ModelCollectionId })),
);

export const ModelCollectionId = () => (
  <Suspense fallback={<PageLoader />}>
    <ModelsPageLazy />
  </Suspense>
);
