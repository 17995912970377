import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { ProVersion } from './types';

import { userKeys } from './queryKeys';

export const useIsProVersionQuery = (options?: UseQueryOptions<ProVersion>) => {
  return useQuery({
    queryFn: async () => {
      const { data } = await axiosInstance.get<ProVersion>('/users/is-pro');

      return data;
    },
    queryKey: userKeys.isPro(),
    ...options,
  });
};
