import { useState } from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { AnimatePresence, motion } from 'framer-motion';

import { useGenerateModelImageMutation } from 'shared/api/models/useGenerateModelImageMutation';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { TextArea } from 'shared/ui/TextArea';
import { toaster } from 'shared/ui/Toast';

import imageSrc from '../assets/image.svg';
import { StepHeader } from './StepHeader';

type Props = {
  isExpanded: boolean;
  onNextClick: () => void;
  onTitleClick: () => void;
};

export const ModelImage = ({ isExpanded, onNextClick, onTitleClick }: Props) => {
  const { isPending: isGenerating, mutateAsync: generateImage } = useGenerateModelImageMutation();

  const { control } = useFormContext();

  const { field: generatedImageField } = useController({
    control,
    name: 'generatedImage',
    rules: { required: true },
  });

  const [imageDescription, setImageDescription] = useState('');

  const handleGenerateImage = async () => {
    try {
      if (!imageDescription) {
        toaster.error('Image description is required in order to generate an image');

        return;
      }

      // TODO: Currently, the API needs a description and model name to generate an image
      const { link } = await generateImage({ description: imageDescription });

      generatedImageField.onChange(link);
    } catch (e) {
      toaster.error('Something went wrong during image generation');
    }
  };

  return (
    <>
      <StepHeader
        icon="imageUp"
        isActive={isExpanded}
        isCompleted={!!generatedImageField.value}
        onClick={onTitleClick}
        title="Model Image"
      />

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            animate={{ height: 'auto' }}
            className="grid grid-cols-1 gap-3 overflow-hidden"
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: 0 }}
            layout
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            <div className="mt-4 flex w-full flex-col gap-4 px-4 md:h-44 md:flex-row">
              <div
                className="relative flex h-44 w-full shrink-0 flex-col items-center justify-center rounded-lg bg-clay-10 md:size-44"
                style={
                  generatedImageField.value
                    ? {
                        backgroundImage: `url(${generatedImageField.value})`,
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                      }
                    : {}
                }
              >
                <img className="mb-2 size-12" src={imageSrc} />
                <Button
                  className="absolute bottom-2 flex cursor-pointer items-center gap-1 rounded-lg bg-white p-1.5"
                  color="secondary"
                  onClick={() => toaster.info('Coming soon')}
                  size="extra-small"
                  variant="filled-light"
                >
                  <Icon className="size-3.5" name="imageUp" />
                  <span className="text-xs font-normal text-clay-900">Upload</span>
                </Button>
              </div>

              <div className="flex h-44 w-full flex-col justify-between">
                <TextArea
                  className="pb-0"
                  disabled={isGenerating}
                  innerClassName="mt-1 grow-0 text-sm"
                  label={
                    <>
                      Write an image description <span className="ml-1 text-red-900">*</span>
                    </>
                  }
                  labelClassName="text-xs font-light text-clay-500"
                  onChange={(e) => setImageDescription(e.target.value)}
                  placeholder="Ex. Spaceship flying through fire"
                  rows={4}
                  value={imageDescription}
                />

                <Button
                  className="self-start border-none bg-clay-900 px-3 hover:brightness-95"
                  isLoading={isGenerating}
                  onClick={handleGenerateImage}
                >
                  <Icon className="mr-1 size-4" name="sparkles" />
                  Generate
                </Button>
              </div>
            </div>

            <span className="px-4 text-xs font-light text-clay-350">
              Allowed file formats: PNG, JPG, WEBP
              <br />
              Maximum file size: 3Mb
            </span>

            <Button className="ml-auto mr-4 rounded-lg px-3" disabled={isGenerating} onClick={onNextClick}>
              Next
            </Button>

            <div className="mt-1 border-b border-clay-20"></div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
