import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const NodesPageLazy = lazy(() => import('./Page').then((module) => ({ default: module.Page })));

export const Nodes = () => (
  <Suspense fallback={<PageLoader />}>
    <NodesPageLazy />
  </Suspense>
);
