import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

const UnsubscribeEmailLazy = lazy(() =>
  import('./UnsubscribeEmail').then((module) => ({ default: module.UnsubscribeEmail })),
);

export const UnsubscribeEmail = () => (
  <Suspense fallback={<PageLoader />}>
    <UnsubscribeEmailLazy />
  </Suspense>
);
