import { useState } from 'react';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Link, useNavigate } from 'react-router-dom';

import { AxiosError } from 'axios';

import { useUser } from 'app/stores/user';
import { Button } from 'shared/ui/Button';
import { PhoneInput } from 'shared/ui/PhoneInput';
import { toaster } from 'shared/ui/Toast';

import { Header } from '../ui/Header';

export const PhoneNumber = () => {
  const navigate = useNavigate();

  const { isUpdating, updateUser, user } = useUser();

  const [phoneNumber, setPhoneNumber] = useState('');

  return (
    <>
      <Header
        description="An extra layer of security is always a good choice."
        title={
          <>
            Add your Phone number
            <br />
            for extra security
          </>
        }
      />

      <form
        className="flex flex-col gap-1"
        onSubmit={async (e) => {
          e.preventDefault();
          try {
            if (user) {
              await updateUser({ phone_number: phoneNumber }, { sendRequest: true });
            }
            navigate('/auth/verify-email', { state: { email: user?.email } });
          } catch (error) {
            if (error instanceof AxiosError) {
              const axiosError = error.response?.data?.message;
              toaster.error(axiosError || 'Something went wrong');
            } else {
              toaster.error('Something went wrong');
            }
          }
        }}
      >
        <PhoneInput
          error={phoneNumber && !isValidPhoneNumber(phoneNumber) ? 'Invalid phone number' : ''}
          onBlur={undefined}
          onChange={function (value): void {
            console.log(value);
            setPhoneNumber(value || '');
          }}
          value={phoneNumber}
        />
        <Button
          className="mt-4"
          disabled={isUpdating || !isValidPhoneNumber(phoneNumber)}
          isLoading={isUpdating}
          size="medium"
          type="submit"
        >
          Continue
        </Button>
      </form>
      <Link
        className="mt-6 block cursor-pointer text-center text-sm font-semibold text-clay-900 duration-200 hover:text-primary-900"
        state={{ email: user?.email }}
        to="/auth/verify-email"
      >
        Skip this step
      </Link>
    </>
  );
};
