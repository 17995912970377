type ExcludedNullish<T> = { [P in keyof T]: Exclude<T[P], null | undefined> };

export const cleanObject = <T extends object>(obj: T, { removeNull }: { removeNull?: boolean } = {}) => {
  return Object.entries(obj).reduce<ExcludedNullish<T>>((acc, [key, value]) => {
    if (removeNull ? !['', null, undefined].includes(value) : value !== undefined) {
      acc[key as keyof T] = value;
    }

    return acc;
  }, {} as ExcludedNullish<T>);
};
