import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { twJoin } from 'tailwind-merge';

import { useUser } from 'app/stores/user';
import { userKeys } from 'shared/api/user/queryKeys';
import { setupInterceptors } from 'shared/config/axiosInstance';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';

import appleSrc from '../assets/apple.svg';
import { AuthButton } from './AuthButton';

export const AppleButton = () => {
  const [isLoadingOpenPage, setIsLoadingOpenPage] = useState(false);

  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isPending } = useUser();

  const queryClient = useQueryClient();

  useEffect(() => {
    const accessToken = searchParams.get('accessToken');
    const appleStatus = searchParams.get('appleStatus');
    const errorMessage = searchParams.get('errorMessage');

    setSearchParams((prev) => {
      const newSearch = new URLSearchParams(prev);
      newSearch.delete('accessToken');
      newSearch.delete('appleStatus');
      newSearch.delete('errorMessage');
      return newSearch;
    });

    if (!appleStatus) return;
    if (appleStatus === 'error') {
      toaster.error(
        errorMessage
          ? `An error occurred: ${errorMessage}`
          : 'Something went wrong while logging in to your apple',
      );
    }
    if (appleStatus === 'success' && accessToken) {
      setupInterceptors(accessToken);
      queryClient.invalidateQueries({
        queryKey: userKeys.singleUser,
      });
    }
    if (appleStatus === 'error-white-list') {
      navigate('/whitelist');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthButton
      className={twJoin(
        'border border-clay-900 bg-clay-900 text-[#F5F6F8]',
        isPending && 'pointer-events-none bg-clay-600',
      )}
      onClick={() => {
        setIsLoadingOpenPage(true);
        const url = new URL('/auth/apple/login', import.meta.env.VITE_API_ENDPOINT);
        window.open(url.toString(), '_self');
      }}
    >
      {!isPending && !isLoadingOpenPage && (
        <>
          <img alt="" className="w-[13px]" src={appleSrc} />
          <span className="mt-0.5 inline-block">Sign in with Apple</span>
        </>
      )}

      {(isPending || isLoadingOpenPage) && <Spinner className="size-5" />}
    </AuthButton>
  );
};
