import { useInfiniteQuery } from '@tanstack/react-query';

import { axiosInstance } from 'shared/config/axiosInstance';

import type { Model } from '../models/types';

import { transactionKeys } from './queryKeys';

type Response = {
  list: LatestModel[];
};

type LatestModel = { timestamp: string; txhash: string } & Model;

export const useLatestUsedModelsInfiniteQuery = (
  { limit = 4 }: { limit: number },
  options: { enabled?: boolean; refetchInterval?: number } = {},
) => {
  return useInfiniteQuery({
    getNextPageParam: (last: LatestModel[] = [], all: LatestModel[][]) =>
      last.length === limit ? all.length : null,
    initialPageParam: 0,
    notifyOnChangeProps: 'all',
    queryFn: async ({ pageParam = 0, signal }) => {
      const res = await axiosInstance.get<Response>(`/transactions/session/latest`, {
        params: { limit, returnModels: true, skip: pageParam * limit },
        signal,
      });

      return res.data.list;
    },
    queryKey: transactionKeys.modelList({ limit }),
    refetchOnWindowFocus: true,
    ...(options || {}),
  });
};
