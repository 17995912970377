import type { ComponentProps } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = ComponentProps<'button'>;

export const AuthButton = ({ className, ...props }: Props) => {
  return (
    <button
      {...props}
      className={twMerge(
        'flex h-11 w-full items-center justify-center gap-2.5 rounded-xl text-sm/5 font-medium shadow-small',
        className,
      )}
    />
  );
};
