import { useController, useFormContext } from 'react-hook-form';

import { AnimatePresence, motion } from 'framer-motion';

import { getCategoryLabel } from 'pages/GalleryHome/helpers/getCategoryLabel';
import { useGetModelTypesQuery } from 'shared/api/models/useGetModelTypesQuery';
import { useUploadModelMutation } from 'shared/api/models/useUploadModelMutation';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Select } from 'shared/ui/Select';

import { StepHeader } from './StepHeader';

type Props = {
  isExpanded: boolean;
  onNextClick: () => void;
  onPrevClick: () => void;
  onTitleClick: () => void;
};

const labelClassName = 'mb-2 text-xs text-xs font-light text-clay-500';

export const Huggingface = ({ isExpanded, onNextClick, onPrevClick, onTitleClick }: Props) => {
  const { isPending: isUploading } = useUploadModelMutation();
  const { data: modelTypes, isLoading: isLoadingModelTypes } = useGetModelTypesQuery({
    allowed_custom: true,
  });

  const { control, formState, register, watch } = useFormContext();
  const [repositoryName, modelName] = watch(['repositoryName', 'modelName']);

  const { field: modelTypeField } = useController({ control, name: 'modelType', rules: { required: true } });

  return (
    <>
      <StepHeader
        icon="huggingface"
        isActive={isExpanded}
        isCompleted={!!repositoryName && !!modelName}
        onClick={onTitleClick}
        title="Huggingface Repository Name"
      />

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            animate={{ height: 'auto' }}
            className="flex flex-col gap-3 overflow-hidden"
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: 0 }}
            layout
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            <div className="mt-4 grid grid-cols-1 gap-1.5 px-4 sm:grid-cols-2">
              <Input
                {...register('repositoryName', { required: 'Repository name is required' })}
                className="sm:col-span-2"
                classNameLabel="mb-2 text-xs font-light text-clay-500"
                error={formState.errors?.['repositoryName']?.message?.toString() || undefined}
                errorSpacing
                // disabled={isUploading}
                label={
                  <>
                    Huggingface Repository Name <span className="ml-1 text-red-900">*</span>
                  </>
                }
                placeholder="ie. meta-llama/Llama-2-7b"
              />
              <Input
                {...register('modelName', { required: 'Model name is required' })}
                classNameLabel={labelClassName}
                error={formState.errors?.['modelName']?.message?.toString() || undefined}
                errorSpacing
                label={
                  <>
                    Model Name <span className="ml-1 text-red-900">*</span>
                  </>
                }
                placeholder="Name your model"
              />
              <Input
                {...register('license', { required: 'License is required' })}
                classNameLabel={labelClassName}
                error={formState.errors?.['license']?.message?.toString() || undefined}
                label={
                  <>
                    License <span className="ml-1 text-red-900">*</span>
                  </>
                }
                placeholder="Key or link"
              />
              <Input
                classNameLabel={labelClassName}
                disabled
                label="Pipeline Tag"
                placeholder="Auto detected"
              />

              <Select
                className="border border-clay-20 bg-white px-3 shadow-sm"
                classNameLabel="mb-2 text-xs font-light text-clay-500"
                disabled={isLoadingModelTypes || isUploading}
                label={
                  <>
                    Model Type <span className="ml-1 text-red-900">*</span>
                  </>
                }
                onValueChange={modelTypeField.onChange}
                placeholder="Add tag to describe type of model"
                value={modelTypeField.value}
              >
                <Select.Content className="z-50">
                  {modelTypes?.types.map(({ model_type }) => {
                    return (
                      <Select.Item key={model_type} value={model_type}>
                        {getCategoryLabel(model_type)}
                      </Select.Item>
                    );
                  })}
                </Select.Content>
              </Select>
            </div>

            <div className="col-span-2 flex justify-between px-4">
              <Button
                className="rounded-lg bg-clay-20 px-3 text-clay-900"
                color="secondary"
                onClick={onPrevClick}
                variant="filled-light"
              >
                Back
              </Button>

              <Button className="rounded-lg px-3" isLoading={isUploading} onClick={onNextClick}>
                Next
              </Button>
            </div>
            <div className="col-span-2 mt-1 border-b border-clay-20"></div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
