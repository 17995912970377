import { motion } from 'framer-motion';

export const Loading = () => {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      className="absolute inset-0 z-[150] flex h-dvh flex-col items-center justify-center gap-6 bg-white"
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5, repeat: 0 }}
    >
      <video
        autoPlay
        className="size-32"
        loop
        muted
        playsInline
        poster="/dodeca-preview.jpg"
        preload="auto"
        src="/white-dodeca-mobile.mp4"
      />

      <div className="relative flex h-1.5 w-full max-w-48 overflow-hidden rounded-full bg-clay-1000 lg:h-2 lg:max-w-64 2xl:h-1.5 2xl:max-w-48">
        <motion.div
          animate={{ maxWidth: '100%', transition: { duration: 2 } }}
          className="absolute inset-y-0 left-0 w-full rounded-full bg-primary-800 bg-gradient-to-r from-primary-900 to-primary-800"
          initial={{ maxWidth: 0 }}
        ></motion.div>
      </div>
    </motion.div>
  );
};
