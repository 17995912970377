import { AnimatePresence } from 'framer-motion';

import { useFilters } from 'features/FiltersProvider/FilterProvider';
import { useModelListQuery } from 'shared/api/models/useGetModelListQuery';
import { AnimateRoute } from 'shared/ui/AnimateRoute';

import type { FilterState } from './ui/GalleryFilters/GalleryFilters';

import { getFiltersParams } from './helpers/getFiltersParams';
import { ActiveModels } from './ui/ActiveModels';
import { Featured } from './ui/Featured';
import { Gallery } from './ui/Gallery';
import { GalleryFilters } from './ui/GalleryFilters';

export const GalleryHome = () => {
  const { filters, onFiltersChange } = useFilters();

  const sortFilters = getFiltersParams(filters);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isPending } = useModelListQuery({
    direction: sortFilters.direction,
    includeAvgScore: true,
    search: filters.globalSearch || undefined,
    sort: sortFilters.sort || undefined,
    tags: filters.tag ? [filters.tag] : undefined,
    type: filters.type || undefined,
  });

  const modelsFull = data?.pages.flat();

  const handleFilterChange = (update: FilterState) => {
    const type = update?.type || filters?.type;

    const newFilters = {
      ...filters,
      ...update,
      featuredEnabled: update.featuredEnabled || false,
      type: type?.includes('all') ? [] : type,
    };

    onFiltersChange(newFilters);
  };

  return (
    <AnimateRoute className="flex flex-1 grow flex-col overflow-y-hidden">
      <GalleryFilters filters={filters} onFilterChange={handleFilterChange} />

      <AnimatePresence>
        {filters.featuredEnabled ? (
          <Featured onFilterChange={handleFilterChange} />
        ) : (
          <Gallery
            fetchNextPage={fetchNextPage}
            filters={filters}
            hasNextPage={hasNextPage}
            isFetchingNextPage={isFetchingNextPage}
            isPending={isPending}
            layout
            modelList={modelsFull}
            onFilterChange={handleFilterChange}
          />
        )}
      </AnimatePresence>

      {!filters.featuredEnabled && <ActiveModels hide={isPending} />}
    </AnimateRoute>
  );
};
