import { type HTMLAttributeAnchorTarget, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { twMerge } from 'tailwind-merge';

import { UploadModelModal } from 'features/UploadModelModal';
import { useMinWidthMediaQuery } from 'shared/hooks/useMediaQuery';
import { Icon, type IconName } from 'shared/ui/Icon';

const LINKS: {
  icon: IconName;
  isMainAction?: boolean;
  label: string;
  target?: HTMLAttributeAnchorTarget;
  to: string;
}[] = [
  { icon: 'box', label: 'Gallery', to: '/' },
  { icon: 'layerDouble', label: 'Ecosystem', to: '/ecosystem' },
  { icon: 'plus', isMainAction: true, label: 'Upload Model', to: '/' },
  { icon: 'grid', label: 'Nodes', to: '/nodes' },
  { icon: 'wallet', label: 'Wallet', to: '/wallet' },
];

export const Footer = () => {
  const sm = useMinWidthMediaQuery('sm');
  const [isUploadOpen, setIsUploadOpen] = useState(false);
  if (sm) return;

  return (
    <footer className="z-20 flex h-fit justify-between rounded-t-lg border-t border-clay-40 bg-clay-10 px-6 pb-4 pt-3">
      {LINKS.map(({ icon, isMainAction, label, to }) => {
        return (
          <NavLink
            className={({ isActive }) =>
              twMerge(
                'flex size-10 flex-col items-center justify-center rounded-lg bg-primary-800 p-2.5',
                !isMainAction && '!border-none !bg-transparent text-sm font-normal text-clay-800',
                isActive && 'text-primary-800',
              )
            }
            key={to + label}
            onClick={() => {
              if (label === 'Upload Model') setIsUploadOpen(true);
            }}
            to={to}
          >
            {({ isActive }) =>
              isMainAction ? (
                <Icon className={twMerge('size-3 text-white')} name={icon} />
              ) : (
                <>
                  <Icon
                    className={twMerge('size-4 text-clay-400', isActive && 'text-primary-800')}
                    name={icon}
                  />
                  {label}
                </>
              )
            }
          </NavLink>
        );
      })}

      {isUploadOpen && <UploadModelModal isOpen={isUploadOpen} onOpenChange={setIsUploadOpen} />}
    </footer>
  );
};
