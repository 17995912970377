import { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AnimatePresence } from 'framer-motion';

import { StripeCheckPaymentHandler } from 'features/useStripePaymentHandler';
import { Auth } from 'pages/Auth/Auth';
import { GoogleCallback } from 'pages/Auth/GoogleCallback';
import { PhoneNumber } from 'pages/Auth/PhoneNumber';
import { RecoveryPassword } from 'pages/Auth/RecoveryPassword';
import { ResetPassword } from 'pages/Auth/ResetPassword';
import { SignIn } from 'pages/Auth/SignIn';
import { SignUp } from 'pages/Auth/SignUp';
import { VerifyEmail } from 'pages/Auth/VerifyEmail';
import { BuildOnNesa } from 'pages/BuildOnNesa';
import { Ecosystem } from 'pages/Ecosystem';
import { EmailVerification } from 'pages/EmailVerification';
import { Faucet } from 'pages/Faucet';
import { GalleryHome as Home } from 'pages/GalleryHome';
import { LeaderBoard } from 'pages/LeaderBoard2';
// import { Faucet } from 'pages/Faucet';
import { Component } from 'pages/Map';
import { ModelCollectionId } from 'pages/ModelCollectionId';
import { ModelCollections } from 'pages/ModelCollections';
import { ModelPage } from 'pages/ModelPage';
import { Models } from 'pages/Models';
import { NodePage } from 'pages/NodePage';
import { Nodes } from 'pages/Nodes';
import { NotFound } from 'pages/NotFound';
import { PasswordRecovery } from 'pages/PasswordRecovery';
import { Preview } from 'pages/Preview';
import { PricingPage } from 'pages/PricingPage';
import { Profile } from 'pages/Profile';
import { QueryHistory } from 'pages/QueryHistory';
import { QueryPage } from 'pages/QueryPage';
import { ResetPassword as OldResetPassword } from 'pages/ResetPassword';
import { Settings } from 'pages/Settings';
import { SignUp as OldSignUp } from 'pages/SignUp';
import { UnsubscribeEmail } from 'pages/UnsubscribeEmail';
import { VerifyEmail as OldVerifyEmail } from 'pages/VerifyEmail';
import { Wallet } from 'pages/Wallet';
import { Whitelist } from 'pages/Whitelist';
// import { RestrictedAccess } from 'shared/provider/RestrictedAccess';
import { Tooltip } from 'shared/ui/Tooltip';

import { AuthGuard } from './providers/AuthGuard';
import { UserContextProvider } from './stores/user';
import { Loading } from './ui/Loading';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
  },
});

export const App = () => {
  // const [isLoading, setIsLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(import.meta.env.MODE !== 'development');

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
      clearTimeout(timeout);
    }, 1500);
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <UserContextProvider>
        <StripeCheckPaymentHandler>
          <Tooltip.Provider>
            <AnimatePresence>{isLoading && <Loading />}</AnimatePresence>
            <AnimatePresence>
              <Routes>
                <Route element={<Preview />} path="/preview" />
                <Route element={<OldSignUp />} path="/signup" />
                <Route element={<UnsubscribeEmail />} path="/unsubscribe-email/:id" />

                <Route element={<Auth />} path="/auth">
                  <Route element={<Navigate replace to="/auth/signin" />} index />
                  <Route element={<SignIn />} path="signin" />
                  <Route element={<SignUp />} path="signup" />
                  <Route element={<VerifyEmail />} path="verify-email" />
                  <Route element={<PhoneNumber />} path="phone" />
                  <Route element={<RecoveryPassword />} path="recovery-password" />
                  <Route element={<ResetPassword />} path="reset-password/:userId/:token" />

                  <Route element={<GoogleCallback />} path="google/callback" />
                </Route>

                <Route element={<OldVerifyEmail />} path="/signup/verify" />

                <Route element={<AuthGuard />}>
                  <Route element={<Home />} path="/" />
                  <Route element={<ModelPage />} path="/models/:id/details" />
                  <Route element={<QueryPage />} path="/models/:id/:section?" />
                  <Route element={<NodePage />} path="/nodes/:id" />
                  <Route element={<Component />} path="/map" />
                  <Route element={<Wallet />} path="/wallet/:section?" />
                  <Route element={<Profile />} path="/account/:id" />
                  <Route element={<Settings />} path="/settings" />
                  <Route element={<QueryHistory />} path="/query-history" />
                  <Route element={<LeaderBoard />} path="/leader-board" />
                  <Route element={<BuildOnNesa />} path="/build-on-nesa" />
                  <Route element={<Faucet />} path="/privilege/faucet" />
                  <Route element={<Ecosystem />} path="/ecosystem" />
                  <Route element={<Nodes />} path="/nodes" />
                  <Route element={<Models />} path="/models" />
                  <Route element={<ModelCollections />} path="/model-collections" />
                  <Route element={<ModelCollectionId />} path="/model-collections/:id" />
                  <Route element={<PricingPage />} path="/pricing" />
                </Route>

                <Route element={<PasswordRecovery />} path="/recovery/password" />

                <Route element={<EmailVerification />} path="/users/:userId/verification/email/:token" />
                <Route element={<OldResetPassword />} path="/users/:userId/reset/password/:token" />

                <Route element={<Whitelist />} path="/whitelist" />
                <Route element={<NotFound />} path="*" />
              </Routes>
            </AnimatePresence>
          </Tooltip.Provider>
        </StripeCheckPaymentHandler>
      </UserContextProvider>
      <ToastContainer />
    </QueryClientProvider>
  );
};
