import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const PricingPageLazy = lazy(() =>
  import('./PricingPage').then((module) => ({ default: module.PricingPage })),
);

export const PricingPage = () => (
  <Suspense fallback={<PageLoader />}>
    <PricingPageLazy />
  </Suspense>
);
