import type { Flags } from 'react-phone-number-input';

import type { FC } from 'react';
import { useMemo } from 'react';

import flags from 'react-phone-number-input/flags';

import phoneCodeList from 'app/config/phoneCodes.json';
import { Select } from 'shared/ui/Select';

interface Option {
  label: string;
  title: string;
  value: string;
}
interface Props {
  iconComponent: FC<{ className: string }>;
  onChange: (value?: string) => void;
  options: Option[];
  value: string;
}

export const CountrySelect = ({
  // iconComponent: IconComponent,
  onChange,
  options,
  value,
}: Props) => {
  const items = useMemo(
    () =>
      options.reduce<Option[]>((acc, { label, value }) => {
        if (!value) return acc;

        const phoneCode = phoneCodeList[value as keyof typeof phoneCodeList];

        if (typeof phoneCode === 'string') {
          acc.push({ label: '+' + phoneCode?.replace('+', '').split('-')[0], title: label, value });
        }

        return acc;
      }, []),
    [options],
  );

  const selected = useMemo(() => items.find((option) => option.value === value), [items, value]);

  const IconComponent = selected && isFlagKey(selected.value) ? flags[selected.value] : null;

  return (
    <div className="mr-4 flex items-center">
      <Select
        className="gap-1 rounded-none bg-transparent text-base text-clay-500 hover:bg-transparent hover:text-primary-800"
        defaultValue={selected?.value}
        icon={
          <div className="mr-1 flex size-4 items-center justify-center overflow-hidden">
            {IconComponent ? (
              <div className="w-4 rounded-sm border border-clay-100">
                <IconComponent title={''} />
              </div>
            ) : null}
          </div>
        }
        onValueChange={(value) => {
          console.log(value);
          onChange(value);
        }}
        titleValue={selected?.label}
        value={selected?.value}
      >
        <Select.Content>
          {items.map((option) => {
            const IconComponent = isFlagKey(option.value) ? flags[option.value] : null;
            return (
              <Select.Item key={option.value} value={option.value}>
                <div className="flex items-center gap-2.5">
                  {IconComponent && (
                    <div className="w-4 rounded-sm border border-clay-100">
                      <IconComponent title={''} />
                    </div>
                  )}
                  {option.title}
                </div>
              </Select.Item>
            );
          })}
        </Select.Content>
      </Select>
      <div className="h-4 w-px bg-clay-100" />
    </div>
  );
};

const isFlagKey = (key: string): key is keyof Flags => {
  return Object.prototype.hasOwnProperty.call(flags, key);
};
