import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const NodePageLazy = lazy(() => import('./Page').then((module) => ({ default: module.Page })));

export const NodePage = () => (
  <Suspense fallback={<PageLoader />}>
    <NodePageLazy />
  </Suspense>
);
