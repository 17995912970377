import type { ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import logoSrc from 'app/assets/images/logo.svg';

type Props = {
  classNameDescription?: string;
  description?: ReactNode;
  imgSrc?: string;
  title: ReactNode;
};

export const Header = ({ classNameDescription, description, imgSrc, title }: Props) => {
  return (
    <div className="mb-11 flex flex-col items-center justify-center gap-5 text-center">
      <img alt="" className="w-16" src={imgSrc || logoSrc} />
      <div>
        <div className="text-2xl/none font-semibold text-clay-900 lg:text-3xl/none 2xl:text-4xl/none">
          {title}
        </div>
        {description && (
          <div
            className={twMerge('mt-4 text-sm/4 text-clay-500 lg:mt-5 2xl:text-base/5', classNameDescription)}
          >
            {description}
          </div>
        )}
      </div>
    </div>
  );
};
