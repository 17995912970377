import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

import { ExternalLink } from 'app/ui/ExternalLink';
import { PRIVACY_LINK, TERMS_LINK } from 'shared/const/links';

type Props = {
  classNameLink?: string;
} & ClassName;

export const TermsLinks = ({ className, classNameLink }: Props) => {
  return (
    <div className={twMerge('flex gap-2', className)}>
      <ExternalLink className={classNameLink} target="_blank" to={TERMS_LINK}>
        Terms of use
      </ExternalLink>
      <div className="h-4 w-px bg-corduroy-200" />
      <ExternalLink className={classNameLink} target="_blank" to={PRIVACY_LINK}>
        Privacy Policy
      </ExternalLink>
    </div>
  );
};
