import { useState } from 'react';

import { loadStripe } from '@stripe/stripe-js';
import { twJoin } from 'tailwind-merge';

import logoSrc from 'app/assets/images/logo.svg';
import { useCreateStripePaymentProMutation } from 'shared/api/stripe';
import { TERMS_LINK } from 'shared/const/links';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Modal } from 'shared/ui/Modal';
import { toaster } from 'shared/ui/Toast';

type Props = {
  onOpenChange: (open: boolean) => void;
  open: boolean;
  redirectPath?: string;
};

const bulletList = [
  { bold: true, text: '1,000/mo Private Inference credits' },
  { bold: true, text: 'Ability to price your custom models highers' },
  { bold: true, text: 'Spotlight your custom models on Featured page' },
  { bold: false, text: 'First access to new models' },
  { bold: false, text: 'Support via Discord tickets' },
  { bold: false, text: 'Nesa Pro Badge' },
];

export const UpgradeToProModal = ({ onOpenChange, open, redirectPath }: Props) => {
  const [isLoadingStripe, setIsLoadingStripe] = useState(false);

  const { mutateAsync: createStripePaymentPro } = useCreateStripePaymentProMutation();

  const upgradeToProByStripe = async () => {
    setIsLoadingStripe(true);
    try {
      const res = await createStripePaymentPro(redirectPath);

      const stripe = await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHED_KEY);

      const resultCheckout = await stripe?.redirectToCheckout({
        sessionId: res.id,
      });

      setIsLoadingStripe(false);
      if (resultCheckout?.error) {
        toaster.error('Something went wrong');
      }
    } catch {
      setIsLoadingStripe(false);
      toaster.error('Something went wrong');
    }
  };

  return (
    <Modal onOpenChange={onOpenChange} open={open}>
      <Modal.Content className="w-full !max-w-[460px]" innerClassName="sm:p-9">
        <header className="flex flex-col gap-5 sm:gap-7">
          <div>
            <img alt="" className="w-16" src={logoSrc} />
          </div>
          <div className="flex items-center gap-2.5">
            <h2 className="text-3xl font-semibold text-clay-900 sm:text-4xl">Upgrade to</h2>
            <div className="rounded-lg bg-[#173BDA] px-2.5 py-2 text-lg/5 font-bold text-white sm:text-xl/6">
              PRO
            </div>
          </div>
        </header>

        <div className="mt-6 flex items-center rounded-xl bg-blue-radial px-6 py-4 sm:mt-9">
          <div className="mr-5 text-3xl font-bold text-white xs:mr-7 xs:text-5xl">5x</div>
          <div className="h-10 w-px bg-white/20"></div>
          <div className="flex-1 text-center text-lg/6 font-semibold text-white xs:text-2xl/7">
            Nesa Playground Points
          </div>
        </div>

        <div className="mb-7 mt-5 grid grid-cols-1 gap-3.5">
          {bulletList.map((el, i) => (
            <div className="flex gap-2.5" key={i}>
              <div className="mt-0.5 size-3.5 min-w-3.5">
                <Icon className="size-3.5 text-green-600" name="check" safeArea="0" />
              </div>
              <div className={twJoin('text-sm', el.bold ? 'font-semibold text-clay-900' : 'text-clay-500')}>
                {el.text}
              </div>
            </div>
          ))}
        </div>

        <div className="mb-6">
          <span className="text-3xl font-bold text-clay-900 sm:text-5xl">$9</span>
          <span className="text-xl text-clay-500 sm:text-2xl/7"> / Month</span>
        </div>

        <div className="grid grid-cols-2 gap-2.5">
          <Button
            isLoading={isLoadingStripe}
            onClick={upgradeToProByStripe}
            size="medium"
            variant="filled-light"
          >
            Pay With Card
          </Button>
          <Button onClick={() => toaster.info('Coming soon')} size="medium">
            Pay with <span className="font-semibold">Crypto</span>
          </Button>
          <div></div>
          <div className="text-center text-xs text-clay-500">One-time payment. 30% off.</div>
        </div>

        <div className="mx-auto mt-4 flex items-center justify-center text-xs font-normal text-clay-400">
          By upgrading, you agree to the{' '}
          <a
            className="ml-1.5 underline duration-200 hover:text-primary-800"
            href={TERMS_LINK}
            rel="noreferrer"
            target="_blank"
          >
            Terms and Conditions
          </a>{' '}
          .
        </div>
      </Modal.Content>
    </Modal>
  );
};

export const UpgradeToProTriggerModal = ({ redirectPath }: { redirectPath: string }) => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <button
        className="inline-flex cursor-pointer items-center gap-2 whitespace-nowrap"
        onClick={() => setOpen(true)}
      >
        <span className="text-sm font-normal text-clay-500">Upgrade to</span>
        <div className="flex h-6 items-center justify-center rounded-md bg-[#173BDA] px-1.5 text-xs/none font-bold text-white">
          <span className="mt-px">PRO</span>
        </div>
      </button>

      <UpgradeToProModal onOpenChange={setOpen} open={open} redirectPath={redirectPath} />
    </>
  );
};
