import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useDebounce } from '@uidotdev/usehooks';
import { twMerge } from 'tailwind-merge';

import { useFilters } from 'features/FiltersProvider/FilterProvider';
import { getCategoryLabel } from 'pages/GalleryHome/helpers/getCategoryLabel';
import { useModelListQuery } from 'shared/api/models/useGetModelListQuery';
import { useGetModelsByNamesQuery } from 'shared/api/models/useGetModelsByNamesQuery';
import { trendingModelNames } from 'shared/const';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';
import { Input } from 'shared/ui/Input';
import { Popover } from 'shared/ui/Popover';
import { Spinner } from 'shared/ui/Spinner';

type Props = {
  className?: string;
  classNameContent?: string;
  classNameInputWrapper?: string;
  classNameTrigger?: string;
  inheritPopoverWidth?: boolean;
  isHotKeySupported?: boolean;
  placeholder?: string;
};

const OPEN_SEARCH_KEYS = ['k', 'meta'];

export const Search = ({
  className,
  classNameContent,
  classNameInputWrapper,
  classNameTrigger,
  inheritPopoverWidth = false,
  isHotKeySupported = true,
  placeholder = 'Find 120,000+ models on-chain',
}: Props) => {
  const navigate = useNavigate();
  const searchRef = useRef<HTMLInputElement>(null);
  const triggerRef = useRef<HTMLInputElement>(null);

  const [searchValue, setSearchValue] = useState('');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { filters, onFiltersChange } = useFilters();

  useEffect(() => {
    if (!isHotKeySupported) return;
    let pressedKeys: string[] = [];

    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key && OPEN_SEARCH_KEYS.includes(e.key.toLowerCase())) {
        pressedKeys = pressedKeys.includes(e.key) ? pressedKeys : [...pressedKeys, e.key];

        if (pressedKeys.length === 2) {
          searchRef.current?.focus();
          pressedKeys = [];
        }
      }
    };
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key && OPEN_SEARCH_KEYS.includes(e.key.toLowerCase())) {
        pressedKeys = pressedKeys.filter((key) => key !== e.key);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [isHotKeySupported]);

  const debouncedValue = useDebounce(searchValue, 300);

  const { data, isPending } = useModelListQuery(
    { limit: 5, search: debouncedValue },
    { enabled: debouncedValue.length > 2 },
  );

  const { data: trendingModels = [], isPending: isTrendingPending } = useGetModelsByNamesQuery({
    names: trendingModelNames,
  });

  const modelsResult = searchValue.length <= 2 ? trendingModels : data?.pages.flat() || [];

  const resetValue = () => {
    onFiltersChange({
      ...filters,
      globalSearch: '',
      type: [],
    });
    setSearchValue('');
    setIsPopoverOpen(false);
  };

  const hasResults = modelsResult.length > 0 && (searchValue.length <= 2 ? !isTrendingPending : !isPending);

  return (
    <form
      className={twMerge('flex w-1/3', className)}
      onSubmit={(e) => {
        e.preventDefault();
      }}
    >
      <Popover onOpenChange={setIsPopoverOpen} open={isPopoverOpen}>
        <Popover.Trigger
          asChild
          className={twMerge(
            'flex w-full shrink-0 grow-0 cursor-pointer items-center gap-0 text-xs font-medium outline-none transition-colors hover:text-primary-900 lg:w-80 xl:w-[32rem] 2xl:w-[40rem]',
            classNameTrigger,
          )}
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
        >
          <div className="w-full" ref={triggerRef}>
            <Input
              className="group justify-items-center"
              classNameInputWrapper={twMerge('rounded-xl px-2 shadow-sm', classNameInputWrapper)}
              endSlot={
                searchValue ? (
                  <Icon
                    className="cursor-pointer text-corduroy-500 transition-colors hover:text-primary-900"
                    name="close"
                    onClick={resetValue}
                  />
                ) : (
                  isHotKeySupported && (
                    <div className="flex gap-0.5">
                      <div className="flex size-6 items-center justify-center rounded border border-clay-100 text-xs text-clay-350 shadow-boxy">
                        ⌘
                      </div>

                      <div className="flex size-6 items-center justify-center rounded border border-clay-100 text-xs text-clay-350 shadow-boxy">
                        K
                      </div>
                    </div>
                  )
                )
              }
              onChange={(e) => {
                e.stopPropagation();
                e.preventDefault();
                const value = e.target.value;

                setSearchValue(value);
              }}
              onFocusCapture={() => {
                setIsPopoverOpen(true);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  onFiltersChange({
                    ...filters,
                    globalSearch: searchValue,
                    type: [],
                  });
                  setIsPopoverOpen(false);
                }
              }}
              placeholder={placeholder}
              ref={searchRef}
              size="small"
              startSlot={
                <Icon
                  className="size-3.5 text-clay-900 transition-colors group-focus-within:text-clay-600 sm:size-4 sm:text-clay-300"
                  name="search"
                />
              }
              value={searchValue}
            />
          </div>
        </Popover.Trigger>
        <Popover.Content
          alignOffset={0}
          className={twMerge('flex w-[calc(100dvw-2rem)] flex-col rounded-lg p-3 ', classNameContent)}
          onOpenAutoFocus={(e) => e.preventDefault()}
          sideOffset={10}
          style={inheritPopoverWidth ? { width: triggerRef.current?.clientWidth } : undefined}
        >
          {!isPending && !modelsResult.length ? (
            <div className="flex flex-col items-center  py-3 ">
              <div className="mx-auto mb-2 flex size-11 items-center justify-center rounded-full border border-clay-20 shadow-boxy">
                <Icon className="size-4 text-clay-350" name="search" />
              </div>
              <h4 className="mb-2 text-lg">No Result Found</h4>

              <div className="mb-3 text-center text-sm font-light text-clay-400">
                <span className="font-normal text-clay-900">“{searchValue}”</span> did not match any models or
                commands. Please try again.
              </div>

              <Button color="secondary" onClick={resetValue} variant="filled-light">
                Clear search
              </Button>
            </div>
          ) : (
            !hasResults && (
              <div className="flex min-h-48 items-center justify-center py-4">
                <Spinner className="size-5" />
              </div>
            )
          )}
          {hasResults && (
            <div className="flex  flex-col ">
              <div className="mb-2 border-b border-clay-20 pb-2 text-sm font-light text-clay-350">
                {searchValue.length <= 2 ? 'Trending Results' : 'Search Results'}
              </div>

              {modelsResult.map((model) => {
                return (
                  <div
                    className="flex h-8 cursor-pointer items-center gap-3 rounded-lg px-2 text-sm font-normal text-clay-700 transition-colors hover:bg-clay-20"
                    key={model._id}
                    onClick={() => {
                      navigate(`/models/${model._id}`);
                      setIsPopoverOpen(false);
                    }}
                  >
                    <div className="flex items-center gap-1 overflow-hidden">
                      <span className="flex items-center gap-1 truncate">
                        <Icon className="size-4 shrink-0 text-clay-350" name="box" />
                        <span className=" truncate">{model.modelName}</span>
                      </span>

                      <span className="size-[2px] rounded-full bg-clay-100"></span>

                      <span className="min-w-fit text-[9px] font-light text-clay-300">{model.org}</span>
                    </div>

                    <div className="ml-auto shrink-0 truncate rounded-[4px] border border-clay-20 bg-white px-1 py-0.5 text-xs uppercase text-clay-300">
                      {getCategoryLabel(model.type)}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </Popover.Content>
      </Popover>
    </form>
  );
};
