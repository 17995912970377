import { Suspense, lazy } from 'react';

import { PageLoader } from 'shared/ui/PageLoader';

export const ProfilePageLazy = lazy(() => import('./Page').then((module) => ({ default: module.Page })));

export const Profile = () => (
  <Suspense fallback={<PageLoader />}>
    <ProfilePageLazy />
  </Suspense>
);
