export const MOCK_NESAS_PICK = [
  'meta-llama/Llama-3.2-1B-Instruct',
  'meta-llama/Llama-3.2-3B-Instruct',
  'Orenguteng/Llama-3-8B-Lexi-Uncensored',
  'gpt-4o',
  'claude-3-opus-20240229',
  'suno/bark-small',
  'MedPaLM-2',
  'nesa/PonyRealism-Uncensored',
  'nesa/LazyMix-Uncensored',
  'ByteDance/Hyper-SD',
  'black-forest-labs/FLUX.1-schnell',
  'yodayo-ai/kivotos-xl-2.0',
  'openai/whisper-small',
  'fluently/Fluently-XL-Final',
  'impira/layoutlm-document-qa',
  'UnfilteredAI/NSFW-gen-v2',
  'CPA',
  'stabilityai/stable-diffusion-xl-base-1.0',
  'gpt-4-turbo-preview',
  'togethercomputer/m2-bert-80M-2k-retrieval',
  'impira/layoutlm-invoices',
  'sd-community/sdxl-flash',
  'openai/whisper-large-v2',
  'dall-e-3',
  'gpt-3.5-turbo-0125',
  'stabilityai/sdxl-turbo',
  'dall-e-2',
  'Corcelio/mobius',
  'GraphST',
  'anton-l/xtreme_s_xlsr_300m_minds14',
  'yodayo-ai/holodayo-xl-2.1',
  'anton-l/ddpm-butterflies-128',
  'facebook/bart-large-cnn',
  'ProsusAI/finbert',
  'facebook/detr-resnet-50',
  'distilbert/distilbert-base-uncased-finetuned-sst-2-english',
  'cardiffnlp/twitter-roberta-base-sentiment-latest',
  'dslim/bert-base-NER',
  'Helsinki-NLP/opus-mt-zh-en',
  'SamLowe/roberta-base-go_emotions',
  'j-hartmann/emotion-english-distilroberta-base',
  'Helsinki-NLP/opus-mt-en-zh',
  'nlptown/bert-base-multilingual-uncased-sentiment',
  'cardiffnlp/twitter-roberta-base-sentiment',
  'philschmid/bart-large-cnn-samsum',
  'sshleifer/distilbart-cnn-12-6',
  'csebuetnlp/mT5_multilingual_XLSum',
  'BAAI/bge-reranker-large',
  'mrm8488/distilroberta-finetuned-financial-news-sentiment-analysis',
  'mattmdjaga/segformer_b2_clothes',
];
