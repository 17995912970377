import { type FormEventHandler } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { AxiosError } from 'axios';

import { Logo } from 'app/ui/Logo';
import { useResetPasswordMutation } from 'shared/api/user/useResetPasswordMutation';
import { AnimateRoute } from 'shared/ui/AnimateRoute';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { toaster } from 'shared/ui/Toast';

type FormValues = {
  password: string;
  passwordConfirmation: string;
};

export const ResetPassword = () => {
  const navigate = useNavigate();
  const { token, userId } = useParams<{ token: string; userId: string }>();
  const { isPending, mutateAsync } = useResetPasswordMutation();

  const {
    formState: { errors, isValid },
    getValues,
    register,
  } = useForm<FormValues>({
    defaultValues: { password: '', passwordConfirmation: '' },
    mode: 'onChange',
  });

  const handleSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    try {
      e.preventDefault();

      if (!token || !userId) {
        toaster.error('Invalid data. Please try again.');
        return;
      }

      const { password } = getValues();

      await mutateAsync({ password, token, userId });

      toaster.success('Password reset was successful');

      navigate('/');
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  return (
    <AnimateRoute className="relative m-auto flex size-full min-h-dvh flex-col items-center justify-center">
      <Logo className="absolute top-12 mb-6 " />

      <form className="flex w-full max-w-96 flex-col gap-2" onSubmit={handleSubmit}>
        <h2 className="mb-4 text-lg font-semibold">Recover your password</h2>

        <Input
          disabled={isPending}
          errorSpacing
          placeholder="New Password"
          type="password"
          {...register('password', {
            validate: (val) => {
              return val.length < 6 ? 'Password should have at least 6 characters' : true;
            },
          })}
          error={errors.password?.message}
        />
        <Input
          disabled={isPending}
          errorSpacing
          placeholder="New Password Confirmation"
          type="password"
          {...register('passwordConfirmation', {
            validate: (value, formValues) =>
              formValues.password !== value ? 'Passwords should match' : value.length >= 6,
          })}
          error={errors.passwordConfirmation?.message}
        />
        <Button disabled={!isValid} isLoading={isPending} type="submit">
          Reset password
        </Button>
      </form>
    </AnimateRoute>
  );
};
