import googleSrc from '../assets/google.svg';
import { AuthButton } from './AuthButton';

export const GoogleButton = () => {
  const handleClick = () => {
    const url = new URL('/auth/google/login', import.meta.env.VITE_API_ENDPOINT);
    window.open(url.toString(), '_self');
  };

  return (
    <AuthButton className="border border-[#E2E4EA] text-clay-900" onClick={handleClick}>
      <img alt="" className="size-[16px]" src={googleSrc} />
      <span className="mt-0.5 inline-block">Continue with Google</span>
    </AuthButton>
  );
};
