import type { MouseEventHandler } from 'react';

import { motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';
import type { IconName } from 'shared/ui/Icon';

import { Icon } from 'shared/ui/Icon';

type Props = {
  icon: IconName;
  isActive?: boolean;
  isCompleted?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  title: string;
} & ClassName;

export const StepHeader = ({ className, icon, isActive, isCompleted, onClick, title }: Props) => {
  return (
    <motion.div
      className={twMerge(
        'flex h-12 shrink-0 cursor-pointer items-center justify-between overflow-hidden  border-b border-clay-20 px-6  py-4 text-clay-500 transition-colors hover:bg-clay-10',
        (isActive || isCompleted) && 'text-clay-800',
        className,
      )}
      key="model-image"
      layout
      onClick={onClick}
    >
      <div className="flex items-center gap-3">
        <Icon className="size-4" name={icon} />
        <span className="text-base font-semibold">{title}</span>
      </div>

      <div
        className={twMerge(
          'size-2.5 rounded-md outline outline-offset-2 outline-clay-20 transition-colors',
          isCompleted && 'bg-primary-800',
        )}
      />
    </motion.div>
  );
};
