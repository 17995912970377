import { useMutation } from '@tanstack/react-query';

import type { UseMutationOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

export type CreateStripePaymentProResponse = {
  id: string;
  url: string;
};

export const useCreateStripePaymentProMutation = (
  options: UseMutationOptions<CreateStripePaymentProResponse, unknown, string | undefined> = {},
) => {
  return useMutation({
    mutationFn: async (redirectPath) => {
      const { data } = await axiosInstance.post<CreateStripePaymentProResponse>(
        '/stripe/upgrade-pro-payment',
        { redirectPath },
      );

      return data;
    },
    ...options,
  });
};
