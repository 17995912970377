import type { FormEvent } from 'react';
import { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { Turnstile } from '@marsidev/react-turnstile';
import { AxiosError } from 'axios';
import { twJoin, twMerge } from 'tailwind-merge';
import isEmail from 'validator/lib/isEmail';

import { useRequestResetPasswordMutation } from 'shared/api/user/useRequestResetPasswordMutation';
import { Button } from 'shared/ui/Button';
import { Input } from 'shared/ui/Input';
import { Spinner } from 'shared/ui/Spinner';
import { toaster } from 'shared/ui/Toast';

import { Header } from '../ui/Header';
import MailIcon from './ui/mail.svg?react';

type FormValues = {
  email: string;
};

export const RecoveryPassword = () => {
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaToken, setCaptchaToken] = useState('');
  const [step, setStep] = useState<'check-email' | 'form'>('form');

  const [timeBlockRequest, setTimeBlockRequest] = useState<null | number>(null);

  const {
    formState: { errors, isValid },
    getValues,
    register,
  } = useForm<FormValues>({
    defaultValues: { email: '' },
    mode: 'onChange',
  });

  const { isPending, mutateAsync } = useRequestResetPasswordMutation();

  const intervalId = useRef<NodeJS.Timeout | null>(null);

  const onSubmit = async (e?: FormEvent<HTMLFormElement>) => {
    try {
      e?.preventDefault();

      if (!isValid) {
        toaster.error('Please fill in the required fields');
        return;
      }

      if (!showCaptcha && !captchaToken) {
        return setShowCaptcha(true);
      }

      if (!captchaToken) {
        toaster.error('You have to complete captcha before submitting a request');

        return;
      }

      const { email } = getValues();

      await mutateAsync({ captchaToken, email: email.trim() });

      toaster.success('Email has been sent. Please check your inbox.');

      setStep('check-email');

      setTimeBlockRequest(30);
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
      intervalId.current = setInterval(() => {
        setTimeBlockRequest((prev) => {
          const cur = (prev || 1) - 1;
          if (!prev) return null;
          return cur;
        });
      }, 1000);
    } catch (e) {
      if (e instanceof AxiosError) {
        const axiosError = e.response?.data?.message;
        toaster.error(axiosError || 'Something went wrong');
      } else {
        toaster.error('Something went wrong');
      }
    }
  };

  useEffect(() => {
    return () => {
      if (intervalId.current) {
        clearInterval(intervalId.current);
      }
    };
  }, []);

  return (
    <>
      {step === 'form' && (
        <>
          <Header
            description="Please enter the email you use to sign in to Nesa"
            title="Forgot your Password ?"
          />
          <form className="flex flex-col gap-2 " onSubmit={onSubmit}>
            <Input
              errorSpacing
              {...register('email', { validate: (val) => (!isEmail(val) ? 'Invalid email' : true) })}
              disabled={isPending}
              error={errors.email?.message}
              label={
                <span>
                  Email <span className="text-red-900">*</span>
                </span>
              }
              placeholder="Email Address"
            />

            <div className={twJoin('mb-4', showCaptcha ? 'flex justify-center' : 'hidden')}>
              <Turnstile
                onSuccess={setCaptchaToken}
                options={{ appearance: 'interaction-only', theme: 'light' }}
                siteKey={import.meta.env.VITE_CLOUDFLARE_SITE_KEY}
                style={{ maxWidth: '100%' }}
              />
            </div>

            <Button isLoading={isPending} type="submit">
              Request password reset
            </Button>
          </form>
          <div className="mt-5 text-center text-sm font-semibold text-clay-900">
            Back to{' '}
            <Link
              className="cursor-pointer text-primary-800 duration-200 hover:text-primary-900"
              to="/auth/signin"
            >
              Sign In
            </Link>
          </div>
        </>
      )}

      {step === 'check-email' && (
        <div className="text-center">
          <div className=" mb-4 flex justify-center">
            <MailIcon className="size-14" />
          </div>
          <div className="text-2xl/none font-semibold text-clay-900 lg:text-3xl/none 2xl:text-4xl/none">
            Check your inbox
          </div>
          <div className="mb-6 mt-4 text-sm/4 lg:mt-5 2xl:text-base/5">
            <span className="font-medium text-clay-900">We sent you an activation link.</span>
            <br />
            <span className="text-clay-500">Please be sure to check your spam folder too.</span>
          </div>
          <div
            className={twMerge(
              'flex items-center justify-center gap-3 text-center text-sm font-semibold',
              timeBlockRequest || isPending
                ? 'cursor-not-allowed text-primary-300'
                : 'cursor-pointer text-primary-800 duration-200 hover:text-primary-900',
            )}
            onClick={() => (timeBlockRequest || isPending ? null : onSubmit())}
          >
            {isPending && <Spinner className="size-5 stroke-primary-300 text-primary-100" />}
            Re-Send Code{timeBlockRequest ? ` (${timeBlockRequest}s)` : null}
          </div>
        </div>
      )}
    </>
  );
};
