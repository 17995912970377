export const getSplittedModelName = (modelName: string = '') => {
  const dividedName = modelName.split('/') || [];
  const orgName = dividedName.length > 1 ? modelName.split('/')?.[0] : '';
  const name = orgName?.length ? modelName.slice(orgName?.length + 1) : modelName;

  if (orgName) {
    return { name, orgName };
  }

  const isOpenAi = modelName?.includes('gpt');
  const isClaude = modelName?.includes('claude');

  if (isOpenAi || isClaude) {
    return { name, orgName: isOpenAi ? 'OpenAI' : 'Anthropic' };
  }

  return { name, orgName };
};
