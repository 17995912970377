import { useQuery } from '@tanstack/react-query';

import type { UseQueryOptions } from 'shared/types';

import { axiosInstance } from 'shared/config/axiosInstance';

import { modelsKeys } from './queryKeys';

type Response = {
  list: {
    categories: string[];
    color?: string;
    count: number;
    id: string;
    tags: string[];
    title: string;
  }[];
  total_count: number;
};

export const useGetFeaturedModelsQuery = (options: UseQueryOptions<Response> = {}) => {
  return useQuery({
    queryFn: async ({ signal }) => {
      const { data } = await axiosInstance.get<Response>(`/models/featured`, { signal });

      return data;
    },
    queryKey: modelsKeys.featured(),
    ...options,
  });
};
