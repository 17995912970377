import { type ComponentProps, forwardRef } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = {
  isError?: boolean;
} & ComponentProps<'input'>;

const Control = forwardRef<HTMLInputElement, Props>(
  ({ checked, className, disabled, isError, ...props }, ref) => {
    return (
      <>
        <input
          {...props}
          checked={checked}
          className="hidden"
          disabled={disabled}
          ref={ref}
          type="checkbox"
        />
        <div
          className={twMerge(
            'relative size-[1.125rem] rounded border border-[#D9DCE4] bg-white shadow-checkbox transition-all duration-200',
            !disabled && 'cursor-pointer group-hover/checkbox:border-primary-800',
            disabled && 'border-clay-100 bg-clay-100',
            isError && 'border-red-800',
            className,
          )}
        >
          {checked && (
            <div className="absolute left-1/2 top-1/2 size-2.5 -translate-x-1/2 -translate-y-1/2 rounded-sm bg-primary-800" />
          )}
        </div>
      </>
    );
  },
);

const CheckboxWrapper = ({ className, ...props }: ComponentProps<'label'>) => {
  return <label {...props} className={twMerge('group/checkbox flex items-start gap-3', className)} />;
};

const CheckboxLabel = ({ className, ...props }: ComponentProps<'div'>) => {
  return <div {...props} className={twMerge('flex-1 text-sm font-medium text-clay-500', className)} />;
};
export const Checkbox = {
  CheckboxLabel,
  CheckboxWrapper,
  Control,
};
