import { useController, useFormContext } from 'react-hook-form';

import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';

import type { IconName } from 'shared/ui/Icon';

import { useUploadModelMutation } from 'shared/api/models/useUploadModelMutation';
import { Button } from 'shared/ui/Button';
import { Icon } from 'shared/ui/Icon';

import { StepHeader } from './StepHeader';

const VISIBILITY = [
  {
    description: 'Anyone on Nesa can see this model. Only you or members of your organization can commit.',
    iconSrc: 'earth',
    label: 'Public',
    value: 'public',
  },
  {
    description: 'Only you or members of your organization can see and commit to this model.',
    iconSrc: 'lock',
    label: 'Private',
    value: 'private',
  },
];

type Props = {
  isExpanded: boolean;
  onPrevClick: () => void;
  onTitleClick: () => void;
};

export const Visibility = ({ isExpanded, onPrevClick, onTitleClick }: Props) => {
  const { isPending: isUploading } = useUploadModelMutation();

  const { control } = useFormContext();

  const { field: modelAccessTypeField } = useController({
    control,
    name: 'modelAccessType',
    rules: { required: true },
  });

  return (
    <>
      <StepHeader
        className={twMerge(!isExpanded && 'border-none')}
        icon="scanEye"
        isActive={isExpanded}
        isCompleted={!!modelAccessTypeField.value}
        onClick={onTitleClick}
        title="Visibility"
      />

      <AnimatePresence>
        {isExpanded && (
          <motion.div
            animate={{ height: 'auto' }}
            className="flex shrink-0 flex-col gap-3 overflow-hidden"
            exit={{ height: 0, opacity: 0 }}
            initial={{ height: 0 }}
            layout
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            <div className="mt-4 flex w-full flex-col gap-3 px-4 md:flex-row">
              {VISIBILITY.map(({ description, iconSrc, label, value }) => {
                return (
                  <div
                    className={twMerge(
                      'flex flex-1 cursor-pointer gap-2 rounded-lg border border-clay-20 bg-white px-6 py-4 transition-colors hover:border-primary-800',
                      value === modelAccessTypeField.value && 'border-primary-150 bg-primary-30',
                      isUploading && 'pointer-events-none opacity-60',
                    )}
                    key={value}
                    onClick={() => modelAccessTypeField.onChange(value as 'private' | 'public')}
                  >
                    <Icon className="size-4 shrink-0 text-primary-800" name={iconSrc as IconName} />
                    <div>
                      <div className="text-base/5 font-semibold">{label}</div>
                      <span className="text-sm/4 font-light text-clay-500">{description}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className="mx-4 mb-4 flex justify-between">
              <Button
                className="rounded-lg bg-clay-20 px-3 text-clay-900"
                color="secondary"
                onClick={onPrevClick}
                variant="filled-light"
              >
                Back
              </Button>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};
