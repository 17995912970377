import type { InputHTMLAttributes, ReactNode } from 'react';
import { forwardRef } from 'react';

import { twMerge } from 'tailwind-merge';

import type { ClassName } from 'shared/types';

export type InputProps = {
  classNameInput?: string;
  classNameInputWrapper?: string;
  classNameLabel?: string;
  endSlot?: ReactNode;
  error?: string;
  errorSpacing?: boolean;
  label?: ReactNode;
  size?: 'medium' | 'small';
  startSlot?: ReactNode;
} & ClassName &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'size'>;

export const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      classNameInput,
      classNameInputWrapper,
      classNameLabel,
      disabled,
      endSlot: EndSlot,
      error,
      errorSpacing,
      label,
      size = 'small',
      startSlot: StartSlot,
      ...inputProps
    }: InputProps,
    ref,
  ) => {
    return (
      <div className={twMerge('relative flex w-full flex-col', errorSpacing && 'pb-4', className)}>
        {label && (
          <label className={twMerge('mb-1.5 text-xs/none text-clay-500 2xl:text-sm', classNameLabel)}>
            {label}
          </label>
        )}
        <div
          className={renderInputClassName({
            classNameInputWrapper,
            disabled,
            endSlot: EndSlot,
            error,
            size,
            startSlot: StartSlot,
          })}
        >
          {StartSlot && <div className="mr-2 inline-flex select-none">{StartSlot}</div>}

          <input
            {...inputProps}
            className={twMerge(
              'h-full w-inherit bg-white/0 text-clay-900 placeholder:font-light placeholder:leading-none placeholder:text-clay-350 focus:outline-none',
              classNameInput,
            )}
            disabled={disabled}
            ref={ref}
          />

          {EndSlot && <div className="ml-2 inline-flex min-w-max select-none">{EndSlot}</div>}
        </div>
        {error && (
          <div className="absolute bottom-3 translate-y-full text-[10px] leading-none text-pink-500">
            {error}
          </div>
        )}
      </div>
    );
  },
);

export function renderInputClassName({
  classNameInputWrapper,
  disabled,
  endSlot,
  error,
  size,
  startSlot,
}: Pick<InputProps, 'classNameInputWrapper' | 'disabled' | 'endSlot' | 'error' | 'size' | 'startSlot'>) {
  return twMerge(
    'flex w-full max-w-full items-center rounded-lg bg-white px-3 font-display transition-colors',
    'border border-clay-20 outline outline-1 outline-offset-0 outline-transparent',
    'focus-within:border-corduroy-300 hover:border-corduroy-300',
    !!error && 'border-pink-500 focus-within:border-pink-500 hover:border-pink-500',
    !!startSlot && 'pl-2',
    !!endSlot && 'pr-2',
    disabled && 'pointer-events-none cursor-not-allowed bg-corduroy-200',
    size === 'small' && 'h-10 text-sm lg:text-sm 2xl:h-10',
    size === 'medium' && 'h-12 text-sm',
    classNameInputWrapper,
  );
}
