import type { PropsWithChildren } from 'react';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';

import { useGetPaymentBySessionIdMutation } from 'shared/api/stripe';
import { userKeys } from 'shared/api/user/queryKeys';
import { toaster } from 'shared/ui/Toast';

const useStripePaymentHandler = () => {
  const queryClient = useQueryClient();

  const [searchParams, setSearchParams] = useSearchParams();

  const paymentSessionId = searchParams.get('paymentSessionId');
  const paymentStatus = searchParams.get('paymentStatus');

  const { mutate } = useGetPaymentBySessionIdMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: userKeys.isPro(),
      });
    },
  });

  useEffect(() => {
    if (!paymentSessionId || !paymentStatus) return;

    if (paymentStatus === 'success') {
      toaster.success(`Your payment is successful`);
      mutate(paymentSessionId);
    }
    if (paymentStatus === 'cancel') {
      toaster.error('Your payment was cancelled');
    }

    setSearchParams((prev) => {
      const newSearch = new URLSearchParams(prev);
      newSearch.delete('paymentSessionId');
      newSearch.delete('paymentStatus');
      return newSearch;
    });
  }, [paymentSessionId, mutate, paymentStatus, setSearchParams]);
};

export const StripeCheckPaymentHandler = ({ children }: PropsWithChildren) => {
  useStripePaymentHandler();
  return children;
};
